<template>
  <router-link :to='isAdmin ? "/artists-list" : "/profil"'>
    <div id="brand"/>
    <div v-if="!store.state.isMobile && store.state.title == null">
      <p>Artspedia Studio</p>
      <p id="sub">Empowering Artists</p>
    </div>
    <p v-if="store.state.title != null" id="title">{{ store.state.title }}</p>
  </router-link>
</template>

<script lang="ts">
import * as Vue from 'vue';
import store from "@/store/index";

export default Vue.defineComponent({
  data() {
    return {
      store,
    }
  },
  computed: {
    isAdmin() {
      return store.state.isAdmin
    }
  }
})
</script>

<style lang="sass" scoped>
p
  font-family: $font-brand
  font-size: 16px

#sub
  font-family: $font-sub
  font-size: 12px
  line-height: 0.8rem

#title
  font-family: $font-main

#brand
  @include square(_(64px))
  background-image: url('@/assets/img/brand.png')
  background-size: contain
  background-repeat: no-repeat

a
  @include row(_(20px))
</style>