import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import store  from "@/store/index"
import router from './router'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.timeout = 60000

let isRefreshing = false

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && store.state.refresh && !isRefreshing) {
        isRefreshing = true;

        const data = {
            refresh: store.state.refresh
        }

        await axios
        .post("/api/auth/token/refresh/", data)
        .then((res) => {
            const token = res.data.access;
            const refresh = res.data.refresh;

            store.commit("setToken", token);
            store.commit("setRefresh", refresh);
            axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        })
        error.config.headers.Authorization = "Bearer " + store.state.token

        return axios(error.config)
    }
    isRefreshing = false
    return Promise.reject(error);
});

const app = createApp(App)
    .use(router)
    .use(store)
    .mount('#app');

app.$.appContext.config.globalProperties.maxAudioSize = 5 * (1024 ** 2)
app.$.appContext.config.globalProperties.maxImageSize = 5 * (1024 ** 2)
app.$.appContext.config.globalProperties.maxVideoSize = 50 * (1024 ** 2)