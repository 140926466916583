<template>
  <div class="container">
    <n-card size="small">
      <template #header>
        <n-space id="form-title" vertical align="center" size="small">
          <div id="brand"/>
          <h3>Login</h3>
        </n-space>
      </template>
      <n-form ref="form" :model="model" :rules="rules">
        <n-form-item id="emailForm" path="email" first label="Email">
          <n-input
              id="email"
              v-model:value="model.email"
              @keydown.enter.prevent
              size="large"
          />
        </n-form-item>
        <n-form-item id="passwordForm" path="password" first label="Password">
          <n-input
              id="password"
              v-model:value="model.password"
              type="password"
              show-password-on="mousedown"
              size="large"
          />
        </n-form-item>
        <n-button
            id="submitBtn"
            type="primary"
            attr-type="submit"
            @click="handleValidateButtonClick"
            size="large"
        >
          <strong>Login</strong>
        </n-button>
      </n-form>
      <n-space style="margin-top: 0.5em">
        <router-link class="router-link" to="/forget-password">
          <strong>Forget password?</strong>
        </router-link>
      </n-space>
      <n-space vertical align="center">
        <br>
        <strong>No account yet?</strong>

        <router-link class="router-link" to="/register">
          <strong>Register now</strong>
        </router-link>
        <br>
      </n-space>
    </n-card>
  </div>
</template>

<style lang="sass" scoped>
button
  background-color: var(--n-color)

h3
  margin: 0

#form-title
  padding: 30px

.container
  justify-content: center
  background-color: $clr-light

.n-card
  max-width: 400px

.n-button
  width: 100%

.router-link
  color: $clr-brand

.router-link:hover
  color: $clr-dark

#brand
  @include square(_(64px))
  background-image: url('@/assets/img/brand.png')
  background-size: contain
  background-repeat: no-repeat
</style>

<script setup lang="ts">
import {ref, getCurrentInstance} from 'vue'
import {useRouter} from 'vue-router'
import store from "@/store/index";
import axios from "axios";
import {
  FormInst, FormItemInst, FormItemRule, FormRules,
  NCard, NForm, NInput, NFormItem, NButton, NSpace
} from 'naive-ui'
import {LoginModel, LoginDto} from '@/types/Login'
import {ValidateError} from "async-validator";
import jwt_decode from "jwt-decode";

const parameters = ["email", "password"]
const router = useRouter()
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message
const model = ref<LoginModel>({
  email: null,
  password: null,
})

const form = ref<FormInst | null>(null)
const passwordFormItem = ref<FormItemInst | null>(null)
const rules: FormRules = {
  email: [
    {
      required: true,
      message: 'Email is required',
      trigger: ['change']
    },
    {
      validator(rule: FormItemRule, value: string) {
        return /\b[\w.\-+]+@[\w.-]+.\w{2,4}\b/.test(value)
      },
      message: 'Email is invalid',
      trigger: ['change']
    }
  ],
  password: [
    {
      required: true,
      message: "Password is required",
      trigger: ['change']
    }
  ]
}

function validateForm(errors: ValidateError[][] | undefined) {
  if (!errors) {
    axios.defaults.headers.common["Authorization"] = "";
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");

    let payload = new LoginDto(model.value)
    axios.post('/api/auth/token/', payload)
        .then((response) => {
          model.value = {
            email: null,
            password: null
          }
          const token = response.data.access;
          const refresh = response.data.refresh;
          store.commit("setToken", token);
          store.commit("setRefresh", refresh);
          store.commit("setIdentity", token);

          axios.defaults.headers.common["Authorization"] = "Bearer " + token;

          message.success("Login is successful!", {showIcon: true, closable: true})
          if (store.state.isAdmin) {
            router.push("/artists-list")
          } else {
            router.push("/profil")
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            message.error('Wrong email or password! Please try again', {showIcon: true, closable: true})
          } else {
            message.error("A problem is occurred when logging in", {showIcon: true, closable: true})
            console.error(error)
          }
        })
  }
}

function handleValidateButtonClick(e: MouseEvent) {
  e.preventDefault()
  form.value!.validate(validateForm).catch(error => {
    message.error('Form login is invalid', {
      showIcon: true, closable: true
    })
  })
}
</script>