import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e0551e14"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = { class: "pagination-item" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "pagination-item" }
const _hoisted_5 = ["disabled"]
const _hoisted_6 = ["onClick", "disabled"]
const _hoisted_7 = { class: "pagination-item" }
const _hoisted_8 = ["disabled"]
const _hoisted_9 = { class: "pagination-item" }
const _hoisted_10 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageFirst = _resolveComponent("PageFirst")!
  const _component_ChevronLeft = _resolveComponent("ChevronLeft")!
  const _component_ChevronRight = _resolveComponent("ChevronRight")!
  const _component_PageLast = _resolveComponent("PageLast")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    _createElementVNode("li", _hoisted_2, [
      _createElementVNode("button", {
        type: "button",
        name: "First",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onClickFirstPage())),
        disabled: _ctx.isInFirstPage,
        style: {"padding":"4px 2px"}
      }, [
        _createVNode(_component_PageFirst, { style: {"font-size":"16px"} })
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("li", _hoisted_4, [
      _createElementVNode("button", {
        type: "button",
        name: "Previous",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onClickPreviousPage())),
        disabled: _ctx.isInFirstPage,
        style: {"padding":"4px 2px"}
      }, [
        _createVNode(_component_ChevronLeft, { style: {"font-size":"16px"} })
      ], 8, _hoisted_5)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pages, (page) => {
      return (_openBlock(), _createElementBlock("li", {
        key: page.name,
        class: "pagination-item"
      }, [
        _createElementVNode("button", {
          type: "button",
          onClick: ($event: any) => (_ctx.onClickPage(page.name)),
          disabled: page.isDisabled,
          class: _normalizeClass({ active: _ctx.isPageActive(page.name) })
        }, _toDisplayString(page.name), 11, _hoisted_6)
      ]))
    }), 128)),
    _createElementVNode("li", _hoisted_7, [
      _createElementVNode("button", {
        type: "button",
        name: "Next",
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onClickNextPage())),
        disabled: _ctx.isInLastPage,
        style: {"padding":"4px 2px"}
      }, [
        _createVNode(_component_ChevronRight, { style: {"font-size":"16px"} })
      ], 8, _hoisted_8)
    ]),
    _createElementVNode("li", _hoisted_9, [
      _createElementVNode("button", {
        type: "button",
        name: "Last",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onClickLastPage())),
        disabled: _ctx.isInLastPage,
        style: {"padding":"4px 2px"}
      }, [
        _createVNode(_component_PageLast, { style: {"font-size":"16px"} })
      ], 8, _hoisted_10)
    ])
  ]))
}