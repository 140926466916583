import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4752b720"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "h" }
const _hoisted_2 = { class: "v" }
const _hoisted_3 = { class: "artist-full-name" }
const _hoisted_4 = { id: "small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile = _resolveComponent("profile")!
  const _component_modal = _resolveComponent("modal")!
  const _component_Check = _resolveComponent("Check")!
  const _component_n_tooltip = _resolveComponent("n-tooltip")!
  const _component_WindowClose = _resolveComponent("WindowClose")!
  const _component_ImageText = _resolveComponent("ImageText")!
  const _component_n_spin = _resolveComponent("n-spin")!

  return (_openBlock(), _createBlock(_component_n_spin, { show: _ctx.load }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["profile-row", { mobile: _ctx.store.state.isMobile }])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: "photo",
            style: _normalizeStyle({'background-image': 'url('+ (_ctx.artist.photo_url || '/') +')', 'background-color': '#FBEEE6'})
          }, null, 4),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_modal, null, {
              trigger: _withCtx(() => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.artist.user.full_name), 1)
              ]),
              content: _withCtx(() => [
                _createVNode(_component_profile, {
                  artist: _ctx.artist,
                  edittable: false
                }, null, 8, ["artist"])
              ]),
              _: 1
            }),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.artist.user.email), 1),
            _createElementVNode("div", {
              class: _normalizeClass(["status", { green: _ctx.artist.status }]),
              ref: "status"
            }, _toDisplayString(_ctx.artist.status ? 'ACTIVE' : 'INACTIVE'), 3)
          ])
        ]),
        _createVNode(_component_n_tooltip, null, {
          trigger: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: "green with-icon",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.terima && _ctx.terima(...args)))
            }, [
              _createVNode(_component_Check, { style: {"font-size":"24px"} })
            ], 512), [
              [_vShow, _ctx.approval && !_ctx.artist.status]
            ])
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Approve ")
          ]),
          _: 1
        }),
        _createVNode(_component_n_tooltip, null, {
          trigger: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: "accent with-icon",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.tolak && _ctx.tolak(...args)))
            }, [
              _createVNode(_component_WindowClose, { style: {"font-size":"24px"} })
            ], 512), [
              [_vShow, _ctx.approval && _ctx.artist.status]
            ])
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Decline ")
          ]),
          _: 1
        }),
        _createVNode(_component_n_tooltip, null, {
          trigger: _withCtx(() => [
            _withDirectives(_createElementVNode("button", {
              class: "brand with-icon",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'detailprofil', params: { id: _ctx.idx } })))
            }, [
              _createVNode(_component_ImageText, { style: {"font-size":"24px"} })
            ], 512), [
              [_vShow, !_ctx.approval]
            ])
          ]),
          default: _withCtx(() => [
            _createTextVNode(" Detail ")
          ]),
          _: 1
        })
      ], 2)
    ]),
    _: 1
  }, 8, ["show"]))
}