<template>
  <div class="container">
    <div class="card">
      <n-space vertical align="center">
        <h3>Add Service</h3>
      </n-space>
      <n-form ref="form" :model="model" :rules="rules">
        <n-form-item id="serviceNameForm" path="service_name" label="Name">
          <n-input
              id="service_name" v-model:value="model.service_name"
              @keydown.enter.prevent size="large"
              placeholder="Service Name"
          />
        </n-form-item>
        <n-form-item id="currencyForm" path="currency" label="Currency">
          <n-select
              id="currency"
              placeholder="Select Currency"
              v-model:value="model.currency"
              :options='currencyOptions'
              filterable
              clearable
              remote
              @keydown.enter.prevent
              @search='currencySearch'
              size="large"
          />
        </n-form-item>
        <div class="price">
          <n-form-item id="priceForm" path="price" label="Price">
            <n-input
                id="price" v-model:value="model.price"
                @keydown.enter.prevent size="large"
                placeholder="100000"
            />
          </n-form-item>
          per
          <n-form-item id="unitForm" path="unit" label="Unit">
            <n-input
                id="unit" v-model:value="model.unit"
                @keydown.enter.prevent size="large"
                placeholder="Hour"
            />
          </n-form-item>
        </div>
        <div>
          Description
          <QuillEditor
              theme="snow"
              content-type="html"
              v-model:content="model.description"
              placeholder="Description"
          />
        </div>
        <n-button
            id="submitBtn" type="primary" attr-type="submit"
            size="large" @click="handleSubmitButtonClick"
        >
          <strong>Add</strong>
        </n-button>
      </n-form>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios, {AxiosError} from "axios"
import {getCurrentInstance, ref} from "vue"
import {useRouter} from "vue-router"
import {
  FormInst, NForm, NFormItem,
  NInput, NButton, NSpace, FormRules, FormItemRule, SelectOption, NSelect
} from "naive-ui";
import {Service, ServiceDto, ChoicesDto} from "@/types/Service";
import {QuillEditor} from "@vueup/vue-quill";
import {ValidateError} from "async-validator";


// Initialize router
const router = useRouter();

// Get service id from params and setup naive-ui messages
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message

// Create ref for Form and Models
const form = ref<FormInst | null>(null)
const model = ref<Service>({} as Service)

var currencies: SelectOption[] = []
const currencyOptions = ref<SelectOption[]>([])

const rules: FormRules = {
  service_name: [
    {
      required: true,
      message: 'Name is required',
      trigger: ['change']
    }
  ],
  currency: [{
    required: true,
    message: 'Currency is required',
    trigger: ['change']
  }],
  price: [
    {
      required: true,
      validator(rule: FormItemRule, value: string) {
        if (!value) {
          return new Error('Price is required')
        } else if (!/^\d*$/.test(value)) {
          return new Error('Price must be numbers')
        }
      },
      trigger: ['change']
    }
  ],
  unit: [{
    required: true,
    message: 'Unit is required',
    trigger: ['change']
  }],
}

function currencySearch(query: string) {
  if (!query.length) {
    currencyOptions.value = currencies
  } else {
    var res: SelectOption[] = currencies.filter((item) => ~item.value.toString().toLowerCase().indexOf(query.toLowerCase()))
    currencyOptions.value = res
  }
}

axios.get("/api/service/currency").then(response => {
  currencies = response.data.map((i: ChoicesDto) => {
    return {value: i.currency_name, label: i.currency_name}
  })
  if (currencies.length == 1) {
    model.value.currency = currencies[0].value
  }
  currencyOptions.value = currencies
}).catch((err: AxiosError) => {
  message.error("Cannot load list of currency", {showIcon: true, closable: true});
})

model.value.description = ''

async function handleSubmit(errors: ValidateError[][] | undefined) {
  if (!errors) {
    let payload = new ServiceDto(model.value);
    axios.post("/api/service/", payload)
        .then(_val => {
          message.success("Service is successfully added!", {showIcon: true, closable: true});
          router.push("/service");
        })
        .catch((err: AxiosError) => {
          message.error("A problem is occurred when adding service", {showIcon: true, closable: true});
        })
  }
}

function handleSubmitButtonClick(e: MouseEvent) {
  e.preventDefault()
  form.value!.validate(validateDesc).catch(error => {
    message.error('Form is invalid', {
      showIcon: true, closable: true
    })
  })
}

function validateDesc(errors: ValidateError[][] | undefined) {
  var content = model.value.description
  if (!content) {
    message.error("Description is required", {
      showIcon: true, closable: true
    })
  } else {
    handleSubmit(errors)
  }
}

</script>

<style lang="sass" scoped>
@import "@vueup/vue-quill/dist/vue-quill.snow.css"

.card
  max-width: 512px

.price
  @include row

#submitBtn
  position: relative
  margin-top: 25px
  margin-left: auto
</style>