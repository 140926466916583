<template>
  <div class="portfolio-grid">
    <div v-show="editable" class="portfolio-card" id="tambah-button" v-on:click="submitPage"></div>
    <div class="portfolio-card" v-for="(porto, i) in portos" :key="i" size="small">
      <div class="portfolio-title-container">
        <div class="portfolio-title">{{ porto.title }}</div>
        <div class="nav-button">
          <modal>
            <template v-slot:trigger>
              <n-tooltip>
                <template #trigger>
                  <button class="with-icon clear">
                    <InformationOutline style="font-size: 24px"/>
                  </button>
                </template>
                View Details
              </n-tooltip>
            </template>
            <template v-slot:content>
              <h3>{{ porto.title }}</h3>
              <div class="v">
                <img :src=porto.file_url alt="" v-if="porto.type === 'Image'">
                <audio controls :src="porto.file_url" v-if="porto.type === 'Audio'">Your browser does not support
                  the audio
                  tag.
                </audio>
                <div class="youtube-modal" v-if="porto.type === 'Youtube'">
                  <iframe class="aspect-ratio-content" title="YouTube Embed" :src="porto.youtube_url"
                          allowfullscreen="allowfullscreen"></iframe>
                </div>
                <div v-if="porto.type === 'Video'">
                  <video class="video-modal"  controls preload="false" 
                    crossorigin="anonymous" cors="anonymous" data-setup='{}'
                    >
                    <source ref="videoSource" :src="porto.file_url" />
                    <!-- <p class="vjs-no-js">To view this video please enable JavaScript</p> -->
                  </video>
                </div>
                <p v-if="!porto.description" style="color: #A9A9AC">Portfolio ini belum memiliki deskripsi</p>
                <div v-else v-html="porto.description"></div>
              </div>
            </template>
          </modal>
          <n-tooltip>
            <template #trigger>
              <button
                  v-show="editable"
                  class="edit with-icon clear"
                  id="edit"
                  @click="edit(porto.id)">
                <PencilOutline style="font-size: 24px"/>
              </button>
            </template>
            Edit
          </n-tooltip>
          <modal :ref="el => modal[i] = el">
            <template v-slot:trigger>
              <n-tooltip>
                <template #trigger>
                  <button v-show="editable" class="hapus with-icon clear" id="delete"
                          @click="() => {currentModalI = i}">
                    <TrashCanOutline style="font-size: 24px"/>
                  </button>
                </template>
                Remove
              </n-tooltip>
            </template>
            <template v-slot:content>
              Are you sure you want to remove portfolio "{{ porto.title }}"?
              <div class="h">
                <n-button
                    type="default"
                    @click="batal(i)">
                  Cancel
                </n-button>
                <n-button
                    class="hapus accent"
                    id="confirm"
                    type="primary"
                    @click="hapus(porto.id)">
                  Remove
                </n-button>
              </div>
            </template>
          </modal>
        </div>
      </div>
      <div class="portfolio-content">
        <img :src=porto.file_url alt="" v-if="porto.type === 'Image'">
        <audio controls :src="porto.file_url" v-if="porto.type === 'Audio'">Your browser does not support the audio
          tag.
        </audio>
        <video v-if="porto.type === 'Video'" controls preload="false" 
          crossorigin="anonymous" cors="anonymous" data-setup='{}' 
          :key="porto.file_url" style="width:100%; height:100%;">
          <source ref="videoSource" :src="porto.file_url"  />
          <!-- <p class="vjs-no-js">To view this video please enable JavaScript</p> -->
        </video>
        <div class="resizable-iframe" v-if="porto.type === 'Youtube'">
          <iframe title="YouTube Embed" :src="porto.youtube_url" 
                allowfullscreen="allowfullscreen" ></iframe>
        </div>
        <p v-if="!porto.description" style="color: #A9A9AC">This portfolio has no description yet</p>
        <div v-else v-html="porto.description"/>
      </div>
    </div>
  </div>
  <pagination
      :maxVisibleButtons="5"
      :totalPages="Math.ceil(totalPortos / (props.editable ? 5 : 6)) || 1"
      :perPage="(props.editable ? 5 : 6)"
      :currentPage="currentPage"
      :artists="portos"
      ref="test"
      @pagechanged="onPageChange"
  />
</template>

<style lang="sass" scoped>
h3
  margin-top: 0
  margin-bottom: _(10px)

.portfolio-title-container
  @include row()
  width: 100%
  height: fit-content
  @include truncate
  margin-bottom: _(10px)
  flex-shrink: 0

  .portfolio-title
    font-weight: bolder
    font-size: 1.17em
    margin: 0
    width: 100%
    @include truncate
//white-space: pre-wrap


.container
  @include fit
  height: fit-content
  z-index: 0
  justify-content: center
  display: flex
  flex-direction: column
  @media (max-width: 1000px)
    width: 100%

#tambah-button
  border: 2px dashed $clr-light-gray
  background-image: url('@/assets/img/add-porto-button.png') 
  //background-size: auto
  background-repeat: no-repeat
  background-position: center
  background-size: 180px 133px
  cursor: pointer

.portfolio-grid
  display: inline-grid
  //flex-direction: row
  height: fit-content
  grid-template-columns: repeat(3, 1fr)
  //flex-wrap: wrap
  gap: 20px

  @media (max-width: 1000px)
    grid-template-columns: repeat(1, 1fr)
    width: 100%
//flex-direction: column

.portfolio-card
  @include pad(_(25px))
  @include col(0px)
  position: relative
  box-sizing: border-box
  border: 2px solid $clr-light-gray
  height: _(550px)
  overflow: hidden
  @media (max-width: 1000px)
    height: _(450px)

.portfolio-content
  @include col(_(20px))
  height: 100%
  flex-shrink: 1
  min-height: 0
  @include truncate
  white-space: pre-wrap

  img
    width: 100%
    max-height: _(350px)
    object-fit: contain

  audio
    width: 100%

  iframe
    width: 100%
    border-style: none
    height: 100%
    position: absolute
    top: 0px
    left: 0px
  
  .resizable-iframe
    position: relative
    padding-bottom: 55%
    height: 0px

.nav-button
  @include row(0px)
  flex-shrink: 0

.v
  @include fit

.youtube-modal
  @include fit

  & > iframe
    height: 100%

button
  background-color: var(--n-color)

.accent:hover
  background-color: $clr-dark

.pagination
  padding-bottom: 1.5em

.youtube-modal
  @include aspect-ratio(16, 9)

.video-modal
  max-height: 90vh
  max-width: 90vw
  width: 100%
  
</style>

<script setup lang="ts">
import {getCurrentInstance, ref} from 'vue'
import {useRouter} from 'vue-router';
import {NButton, NTooltip} from 'naive-ui'
import Pagination from '@/components/Pagination.vue'

import axios from "axios";
import store from "@/store";
import Modal from "@/components/Modal.vue";

import {InformationOutline, PencilOutline, TrashCanOutline} from "mdue";
import {PortfolioModel} from "@/types/PortfolioModel";


const props = defineProps<{
  editable?: boolean,
  url?: string
}>()

const portos = ref<PortfolioModel[] | null>([])
const totalPortos = ref(0)
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message
const modal = ref([])
const currentModalI = ref(0)
const currentPage = ref(1)

getPortfolio(currentPage.value)

async function getPortfolio(page: any) {
  await axios.get(props.url + `?page=${page}`)
      .then(response => {
        portos.value = response.data.results
        totalPortos.value = response.data.count
      })
      .catch(error => console.log(error))
}

const router = useRouter();
const test = ref(null)

function hapus(id: number) {
  axios.delete(`/api/portfolio/${id}`,
      {headers: {Authorization: `Bearer ${store.state.token}`}}
  )
      .then(response => {
        message.success("Portfolio is successfully removed!", {showIcon: true, closable: true})
        getPortfolio(currentPage.value);
        (modal.value as any)[currentModalI.value].showModal = false;
      })
      .catch((error) => {
        message.error("A problem is occurred when removing portfolio. Please try again", {
          showIcon: true,
          closable: true
        })
        console.log(error)
      })
}

function batal(i: number) {
  console.log(test);
  (modal.value as any)[i].showModal = false
}

function edit(id: number) {
  router.push('/portfolio/edit/' + id)
}

function submitPage() {
  router.push('/portfolio/submit')
}

function onPageChange(page: number) {
  currentPage.value = page;
  getPortfolio(page);
}
</script>