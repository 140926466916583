<template>
  <div class="container">
    <div class="card" v-if="artist">
      <profile :artist="artist" :editable="true"/>
    </div>
  </div>
</template>

<script setup lang="ts">
import {ref} from "@vue/runtime-core"
import {ArtistModel} from "@/types/ArtistModel";
import store from "@/store/index.js"
import axios from "axios";
import Profile from "@/components/Profile.vue"

const artist = ref<ArtistModel | null>()

axios.get('/api/profile/',
    {headers: {Authorization: `Bearer ${store.state.token}`}}
).then(
    response => {
      artist.value = response.data
      store.commit("setPhotoUrl", response.data.photo_url)
    }).catch(
    error => console.log(error)
)

</script>