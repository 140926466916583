<template>
  <div class="service-grid">
    <div v-show="editable" class="service" id="tambah" v-on:click="submit">
      <PlusBox style="font-size: 32px"></PlusBox>
      Add new service
    </div>

    <n-tooltip>
      <template #trigger>
        <div class="service name head" id="nama-layanan-head" v-on:click="onSortFilter('service_name')">
          <div style="flex-shrink: 1">Service Name</div>
          <svg :class="{ flip : sort == 'service_name' && order == 'desc'}" height="24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
            <g fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="#E5E5E5" d="M3 9l4-4l4 4M7 5v14"></path>
              <path :class="{ sort : sort == 'service_name'}" stroke="#E5E5E5" d="M21 15l-4 4l-4-4m4 4V5"></path>
            </g>
          </svg>
        </div>
      </template>
      Sort {{ order == 'desc' ? "Descending" : "Ascending" }}
    </n-tooltip>
    <n-tooltip>
      <template #trigger>
        <div class="service price head" id="harga-layanan-head" v-on:click="onSortFilter('price')">
          <div style="flex-shrink: 1">Price / Unit</div>
          <svg :class="{ flip : sort == 'price' && order == 'desc'}" height="24" width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24">
            <g fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path stroke="#E5E5E5" d="M3 9l4-4l4 4M7 5v14"></path>
              <path :class="{ sort : sort == 'price'}" stroke="#E5E5E5" d="M21 15l-4 4l-4-4m4 4V5"></path>
            </g>
          </svg>
        </div>
      </template>
      Sort {{ order == 'desc' ? "Descending" : "Ascending" }}
    </n-tooltip>
    <div class="service actions head">
      Action
    </div>

    <div class="service-divider"></div>

    <template
        v-for="(service, i) in services"
        :key="i"
    >
      <div class="service name" id="nama-layanan">
        {{ service.service_name }}
      </div>
      <div class="service price" id="harga-layanan">
        {{ formatMoney(service.price, service.currency.currency_code, service.unit) }}
      </div>
      <div class="service actions">
        <modal>
          <template v-slot:trigger>
            <n-tooltip>
              <template #trigger>
                <button class="with-icon clear">
                  <InformationOutline style="font-size: 24px"/>
                </button>
              </template>
              View Details
            </n-tooltip>
          </template>
          <template v-slot:content>
            <h3>{{ service.service_name }}</h3>
            <span>{{ formatMoney(service.price, service.currency.currency_code, service.unit) }}</span>
            <div v-html="service.description"></div>
          </template>
        </modal>
        <n-tooltip>
          <template #trigger>
            <button
                v-show="editable"
                class="edit with-icon clear"
                id="edit"
                @click="edit(service.id)">
              <PencilOutline style="font-size: 24px"/>
            </button>
          </template>
          Edit
        </n-tooltip>
        <modal :ref="el => modal[i] = el">
          <template v-slot:trigger>
            <n-tooltip>
              <template #trigger>
                <button v-show="editable" class="hapus with-icon clear" id="delete" @click="() => {currentModalI = i}">
                  <TrashCanOutline style="font-size: 24px"/>
                </button>
              </template>
              Remove
            </n-tooltip>
          </template>
          <template v-slot:content>
            Are you sure you want to remove layanan "{{ service.service_name }}"?
            <div class="h">
              <button
                  @click="batal(i)">
                Cancel
              </button>
              <button
                  class="hapus accent"
                  id="confirm"
                  @click="hapus(service.id)">
                Remove
              </button>
            </div>
          </template>
        </modal>
      </div>
    </template>
  </div>
  <pagination
      :maxVisibleButtons="5"
      :totalPages="Math.ceil(totalServices/10) || 1"
      :perPage="10"
      :currentPage="currentPage"
      @pagechanged="onPageChange"
  />
</template>

<style lang="sass" scoped>
svg
  transition: 0.2s
h3
  margin: 0
  font-size: larger


#nama-layanan-head
  &:hover
    cursor: pointer
    @include shade

#harga-layanan-head
  &:hover
    cursor: pointer
    @include shade


.service-grid
  display: grid
  grid-template-columns: 60% 20% 20%
  box-sizing: border-box
  @media (max-width: 900px)
    grid-template-columns: 45% 45% 10%

.service
  @include pad(10px)
  @include col
  justify-content: center
  flex-wrap: wrap

  &.head
    flex-direction: row
    justify-content: space-between
    font-size: larger
    font-weight: 700

  &.actions
    @include row
    justify-content: flex-start

.service-divider
  grid-column: span 3
  height: 1px
  background: $clr-brand

.flip
  transform: rotate(180deg)

.sort
  transition: 0.2s
  stroke: $clr-brand
</style>

<script setup lang="ts">
import {getCurrentInstance, ref} from 'vue';
import Pagination from '@/components/Pagination.vue';
import Modal from "@/components/Modal.vue";
import axios from 'axios'
import store from "@/store/index.js"

import {InformationOutline, PencilOutline, PlusBox, TrashCanOutline, DotsVertical} from "mdue";
import {NTooltip, NPopover} from 'naive-ui'
import json from "@/mock.json";
import {useRouter, useRoute} from "vue-router";
import {Service} from "@/types/Service";

interface LooseObject {
  [key: string]: any
}


// Props
const props = defineProps<{
  editable?: boolean,
  url?: string
}>()

// Exported variables
// Data
const services = ref<Service[] | null>();
const totalServices = ref(0);

// Message Naive UI
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message

// Modal stuffs
const modal = ref([])
const currentModal = ref(0)

// Pagination stuffs
const currentPage = ref(1)

// Misc
const router = useRouter();

// Sort
let sort = ref("id")
let order = ref("asc")


// Setup logic
getServices(currentPage.value)


// Functions
async function getServices(page: any) {

  const query: LooseObject = {}
  currentPage.value = page;

  if (sort.value !== '') {
    if (sort.value === 'id' || sort.value === 'service_name' || sort.value === 'price') {
      page = `${page}&ordering=${order.value === 'desc' ? '-' : ''}${sort.value}`
      query.sortby = sort
      query.order = order
    }
  }

  if (currentPage.value > 1)
    query.page = currentPage.value


  await axios.get(props.url + `?page=${page}`)
      .then(response => {
        services.value = response.data.results
        totalServices.value = response.data.count
      })
      .catch(error => console.log(error))


}

function submit() {
  router.push('/service/add')
}


function edit(id: number) {
  router.push('/service/edit/' + id)
}

function hapus(id: number) {
  axios.delete(`/api/service/${id}`)
      .then(response => {
        message.success("Service is successfully removed!", {showIcon: true, closable: true})
        getServices(currentPage.value);
        (modal.value as any)[currentModal.value].showModal = false;
      })
      .catch((error) => {
        message.error("A problem is occurred when removing service. Please try again", {showIcon: true, closable: true})
        console.log(error)
      })
}

function batal(i: number) {
  (modal.value as any)[i].showModal = false
}

function onPageChange(page: number) {
  currentPage.value = page;
  getServices(page);
}

function onSortFilter(value: string) {
  sort.value = value
  order.value = (order.value === 'asc') ? 'desc' : 'asc'
  currentPage.value = 1;
  getServices(1);
}

function formatMoney(money: number, namaUang: string, unit: string) {
  let formattedMoney = money.toLocaleString()
  return namaUang + " " + formattedMoney + " / " + unit
}
</script>