<template>
    <div class="container">
        <n-card size="small" style="text-align: center; padding: 2em">
            <template #header>
                <n-space id="form-title" vertical align="center" size="small">
                    <div id="brand" />
                </n-space>
            </template>
            <n-p style="margin-top: 1em">
                {{ msgRef }}
            </n-p>
            <n-space v-if="resendMail" justify="end" align="center" style="margin-top: 2em">
                <n-text depth="3" :type="resendMsgType">
                    {{ resendMsg }}
                </n-text>
                <n-button
                    type="primary"
                    :loading="resendLoading" 
                    :disabled="resendDisabled"
                    icon-placement="left" 
                    @click="resendClick"
                >
                    Resend link
                </n-button>
            </n-space>  
        </n-card>
    </div>
</template>

<style lang="sass" scoped>
.n-card
    max-width: 500px
    width: 100%
.container
    justify-content: center
    background-color: $clr-light
.router-link
    color: $clr-brand
.router-link:hover
    color: $clr-dark
#brand
    @include square(_(64px))
    background-image: url('@/assets/img/brand.png')
    background-size: contain
    background-repeat: no-repeat
</style>

<script setup lang="ts">
import { ref, getCurrentInstance, watch } from 'vue'
import { useRouter } from 'vue-router'
import { 
    NText, NP, NCard, NSpace, NButton
} from 'naive-ui'
import axios from 'axios'
import store from '@/store'
const router = useRouter()
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message
const props = defineProps<{
    token?: string,
    fromRegister?: string
}>()

const msgRef = ref("")
const resendMail = ref(false)
const resendLoading = ref(false)
const resendDisabled = ref(false)
const resendMsg = ref("Email is not received yet?")
const resendMsgType = ref("")
const resendCoolDown = ref(0)
let coolDownTimeout = ref(0)
function resendCoolDownFunc(val: number, prev: number) {
    if (prev < val) {
        if (coolDownTimeout.value) {
            clearInterval(coolDownTimeout.value)
        }
        coolDownTimeout.value = setInterval(() => {
            resendLoading.value = false
            resendDisabled.value = true
            resendCoolDown.value -= 1
            resendMsgType.value = "error"
            resendMsg.value = `Resend email after ${resendCoolDown.value} seconds`
        }, 1000)
    } else if (!val) {
        clearInterval(coolDownTimeout.value)
        resendLoading.value = false
        resendDisabled.value = false
        resendMsgType.value = ""
        resendMsg.value = `Email is not received yet?`
    }
}
watch(resendCoolDown, resendCoolDownFunc)
function resendClick(e: MouseEvent) {
    resendLoading.value = true
    resendDisabled.value = true
    axios.post("/api/auth/verification/")
    .then(resp => {
        resendLoading.value = false
        resendDisabled.value = true
        resendCoolDown.value = resp.data.timeout
        message.success("Link is successfully sent", {showIcon: true, closable: true})
    })
    .catch(error => {
        if (error.response.status == 409 || error.response.status == 401) {
            router.push("/logout")
            return
        } 
        
        if (error.response.status == 429) {
            resendCoolDown.value = parseInt(error.response.data.detail.match(/\d+/))
            return
        }

        resendLoading.value = false
        resendDisabled.value = false

        message.error("A problem is occurred when sending the link", {showIcon: true, closable: true})
    })
}

if (props.fromRegister) {
    msgRef.value = "Verification email is sent. Please check your email inbox."
    resendMail.value = true
} else if (props.token) {
    axios.get("/api/auth/verification/" + props.token + "/")
    .then(resp => {
        msgRef.value = "Email verification is successful!"
        message.success("Email is successfully verified, please login.", {showIcon: true, closable: true})
        router.push("/logout")
    })
    .catch(error => {
        if ('message' in (error.response.data || {})) {
            msgRef.value = error.response.data.message
        } else {
            msgRef.value = "A problem is occurred"
        }
        message.error("Failed verifying email", {showIcon: true, closable: true})
    })
} else if (store.state.isAuthenticated && !store.state.isEmailVerified) {
    msgRef.value = "Your email is not verified yet. Please check your email inbox untuk menerima link verifikasi email."
    resendMail.value = true
} else {
    router.push("/login")
}

</script>