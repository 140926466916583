import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MessageWrapper = _resolveComponent("MessageWrapper")!
  const _component_NavBar = _resolveComponent("NavBar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_n_message_provider = _resolveComponent("n-message-provider")!
  const _component_n_config_provider = _resolveComponent("n-config-provider")!

  return (_openBlock(), _createBlock(_component_n_config_provider, {
    locale: _ctx.locale,
    "theme-overrides": _ctx.naiveThemeOverride,
    breakpoints: {s:900}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_n_message_provider, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_MessageWrapper),
            _createVNode(_component_NavBar, {
              ref: "navbar",
              style: {"z-index":"1000"}
            }, null, 512),
            _createElementVNode("div", {
              id: "wrapper",
              class: _normalizeClass({ mobile: _ctx.store.isMobile})
            }, [
              _createVNode(_component_router_view)
            ], 2)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["locale", "theme-overrides"]))
}