export interface Service {
    id: number,
    user: string,
    service_name: string,
    currency: string,
    price: number,
    unit: string
    description: string,
}

export interface ChoicesDto {
    currency_name: string
}

export class ServiceDto {
    service_name: string | null
    currency: string | null
    price: number | null
    unit: string | null
    description: string | null

    constructor(model: Service) {
        this.service_name = model.service_name
        this.currency = model.currency
        this.price = model.price
        this.unit = model.unit
        this.description = model.description
    }
}