<template>
  <div class="container">
    <div class="card">
      <div class="card-content">
        <div class="card-header">
          <div class="card-gap"></div>
          <div class="artist-pic"
               v-bind:style="{ backgroundImage: `url( ${ artist!.photo_url || require('@/assets/img/photo-placeholder.png')})`}"></div>

          <label>
            <input type="file" style="display: none;" accept="image/*" v-on:change="upload" id="profile-image"/>
            <div class="ganti-button" style="cursor: pointer">Change Profile Photo</div>
          </label>
          <button class="reset-button" v-on:click="reset" v-if="has_changed">Reset</button>
        </div>
        <div class="sep"></div>
        <div class="card-body">

          <div class="card-body-content">
            <div class="card-body-text">
              <n-form ref="formRef" :model="artist" :rules="rules">
                <n-form-item id="artist-email-form" path="artist.user.email" label="Email" disabled>
                  <n-input
                      id="user-email" v-model:value="artist!.user.email"
                      @keydown.enter.prevent size="large"
                      disabled
                  />
                </n-form-item>

                <n-form-item id="artist-fullname-form" path="user.fullname" label="Full Name">
                  <n-input
                      id="service_name" v-model:value="artist!.user.full_name"
                      @keydown.enter.prevent size="large"
                      placeholder="Enter your full name"
                  />
                </n-form-item>

                <n-form-item id="artist-aliasname-form" path="artist.aliasname" label="Alias Name">
                    <n-input
                        id="aliasname" v-model:value="artist!.aliasname"
                        @keydown.enter.prevent size="large"
                        placeholder="Enter your aliasname" 
                        style=""
                    />
                </n-form-item>

                <n-form-item id="artist-categories-form" path="artist.categories" label="Category">
                  <n-select 
                    id="categories"
                    v-model:value="artist!.categories" 
                    multiple clearable filterable remote
                    size="large"
                    @keydown.enter.prevent
                    :options='categoryOptions'
                    @search='categorySearch' 
                    />
                </n-form-item>

                <n-form-item id="artist-cities-form" path="artist.cities" label="City">
                  <n-select 
                    id="cities"
                    v-model:value="artist!.cities" 
                    multiple clearable filterable remote
                    size="large"
                    @keydown.enter.prevent
                    :options='cityOptions'
                    @search='citySearch'
                  />
                </n-form-item>

                <n-form-item id="artist-description-form" path="user.description" label="Description">
                  <n-input
                      v-model:value="artist!.user.description"
                      placeholder="Tell us about yourself. How long have you been working? What kind of work do you usually do? Etc."
                      type="textarea"
                      :autosize="{
                      minRows: 3,
                      maxRows: 5
                    }"
                  />
                </n-form-item>

                <n-form-item id="artist-address-form" path="address" label="Address">
                  <n-input
                      id="address"
                      v-model:value="artist!.address"
                      type="textarea"
                      @keydown.enter.prevent
                      placeholder="Enter your address" 
                      :autosize="{
                        minRows: 2,
                        maxRows: 5
                      }"
                  />
                </n-form-item>

                <n-form-item id="artist-phone_number-form" path="artist.phone_number" label="Phone number">
                  <n-input
                      id="phone_number" 
                      v-model:value="artist!.phone_number"
                      @keydown.enter.prevent
                      placeholder="Enter your phone number"
                  />
                </n-form-item>


                <n-form-item v-if="artist!.status" id="artist-website" path="artist.website" label="Artist Website">
                  <n-input
                      id="website" 
                      v-model:value="artist!.website"
                      @keydown.enter.prevent
                      placeholder="Enter the URL/link to your online portfolio, for example Instagram or YouTube. Potential clients can look at your other works."
                  />
                </n-form-item>

                <n-form-item v-if="!artist!.status" id="artist-portfolioUrlRegForm" path="artist.portfolio_url_reg" label="Portfolio URL for Registration" >
                  <n-input
                      id="portfolio_url_reg" 
                      v-model:value="artist!.portfolio_url_reg"
                      @keydown.enter.prevent size="small"
                      type="textarea"
                      placeholder="Enter the URL or link to your online portfolio, for example Instagram or YouTube. This will be used to verify your account."
                  />
                </n-form-item>
              </n-form>
            </div>
            <div class="button-container">
              <button class="brand" id="kembali-button" v-on:click="back">Cancel</button>
              <n-button id="simpan-button" :loading="submitLoading" @click="call" icon-placement="left"
                        attr-type="submit" type="primary">
                Update
              </n-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, ref, computed, watch, nextTick} from "@vue/runtime-core"
import {useRouter} from 'vue-router';
import axios from 'axios'
import cities from '../cities'
import store from "@/store/index.js"
import {
  AutoCompleteInst, NButton, NForm, NFormItem, NInput, FormInst, 
  NSelect, SelectOption, FormRules, FormItemRule
} from "naive-ui";
import {getCurrentInstance} from "vue";
import {ArtistModel} from "@/types/UserModel";
import {CategoryModel} from "@/types/CategoryModel";
import { CityModel } from "@/types/ArtistModel";


const abc = cities
const categoryOptions = ref<SelectOption[]>([])
const cityOptions = ref<SelectOption[]>([])
let categoryModelList: CategoryModel[] = []
let cityModelList: CityModel[] = []
var artistActiveStatus = true

// Store categories data from backend
var categoryData: SelectOption[] = []
var cityData: SelectOption[] = []


export default defineComponent({
  setup() {
    const submitLoading = ref(false)
    const app = getCurrentInstance()
    const message = app!.appContext.config.globalProperties.message
    const autoCompleteInstRef = ref<AutoCompleteInst | null>(null)
    watch(autoCompleteInstRef, (value) => {
      if (value) nextTick(() => value.focus())
    })
    const inputValueRef = ref('')
    const options = computed(() => {
      if (!inputValueRef.value) {
        return []
      }
      return abc.filter((item) =>

          ~item.toLowerCase().indexOf(inputValueRef.value.toLowerCase())).map((suffix) => {
        return {
          label: suffix,
          value: suffix
        }
      })
    })
    
    const router = useRouter();
    const formRef = ref<FormInst | null>(null)
    let artistRef = ref<ArtistModel | null>({
      user: {
        email: null,
        full_name: "[USER FULL NAME]",
        description: "[USER DESCRIPTION]"
      },
      aliasname: null,
      photo_url: null,
      status: true,
      categories: ["USER_CATEGORY_1", "USER_CATEGORY_2"],
      cities: ["USER_CITY_1", "USER_CITY_2"],
      portfolio_url_reg: "",
      address: "",
      phone_number: "",
      website: ""
    })

    const categories = ref<CategoryModel[]>([{
      category_id: 0,
      name: null,
      slug: null
    }])

    const cities = ref<string[]>([])

    axios.get('/api/profile/',
      {headers: {Authorization: `Bearer ${store.state.token}`}}
    ).then(
        response => {
          artistRef.value = response.data
          artistActiveStatus = response.data["status"]
    }).catch(
      error => console.log(error)
    )

    axios.get('/api/profile/category').then(
      response => {
        categoryModelList = response.data
        categoryOptions.value = response.data.map((el: CategoryModel) => {
          return {value: el.name, label: el.name}
        })
        categoryData = response.data.map((el: CategoryModel) => {
          return {value: el.name, label: el.name}
        })
        // console.log(categoryOptions.value)
        // categories.value = response.data
    }).catch(
        error => console.log(error)
    )

    axios.get('/api/profile/city').then(
      response => {
        cityModelList = response.data
        cityOptions.value = response.data.map((el: CityModel) => {
          return {value: el.name, label: el.name}
        })
        cityData = response.data.map((el: CityModel) => {
          return {value: el.name, label: el.name}
        })
        // console.log(categoryOptions.value)
        // categories.value = response.data
    }).catch(
        error => console.log(error)
    )

    function categorySearch(query: string) {
        if (!query.length) {
            categoryOptions.value = categoryData
        } else {
            var res: SelectOption[] = categoryData.filter((item) => {
              if (item != undefined && item.value != undefined)
                return ~item.value.toString().toLowerCase().indexOf(query.toLowerCase())
            })
            categoryOptions.value = res
        }
    }

    function citySearch(query: string) {
      if (!query.length) {
          cityOptions.value = cityData
      } else {
          var res: SelectOption[] = cityData.filter((item) => {
              if (item != undefined && item.value != undefined)
                return ~item.value.toString().toLowerCase().indexOf(query.toLowerCase())
          })
          cityOptions.value = res
      }
    }

    function validateAliasname(rule: FormItemRule, value: string): Promise<void> {
      return new Promise<void>((resolve, reject) => {
        console.log("before calling API")
        axios.get('/api/profile/check_aliasname', {
          params: {
            name: artistRef.value!.aliasname,
            email: artistRef.value!.user.email
          }
        })
        .then((response) => {
          if (response.data['exist']) {
            // invalidate form field aliasname  
            console.log("aliasname not available")
            reject(Error('Alias name is not available'))
          } else {
            console.log("aliasname available")
            resolve()
          }
        })
      })
    }

    const rules: FormRules = {
      artist: {
        user: {
          email: [{
            required: true,
            message: 'Email is required',
            trigger: ['change']
          }]
        },
        aliasname: [{
          required: true,
          message: 'Alias name is required',
          trigger: ['change']
        }, {
          key: 'uniqueAliasName',
          validator: validateAliasname,
          message: 'Alias name is not available',
          trigger: ['blur']
        }], 
        categories: [{
          required: true,
          validator (rule: FormItemRule, value: string): boolean {
              return value.length > 0
          },
          message: 'At least 1 artist category is required',
          trigger: ['change']
        }],
        cities: [{
          required: true,
          validator (rule: FormItemRule, value: string): boolean {
              return value.length > 0
          },
          message: 'At least 1 city is required',
          trigger: ['change']
        }], 
        portfolio_url_reg: [{
          required: true,
          message: 'URL/link to existing portfolio is required',
          trigger: ['change']
        }],
    }
  }

    return {
      submitLoading: submitLoading,
      router: router,
      message: message,
      artist: artistRef,
      formRef: formRef,
      categories: categories,
      cities: cities,
      store,
      autoCompleteInstRef,
      inputValue: inputValueRef,
      options, rules,
      categoryOptions, cityOptions,
      categoryModelList, cityModelList,
      categorySearch, citySearch,
      artistActiveStatus
    };
  },

  data() {
    return {
      original_image: '',
      has_changed: false,
      form: new FormData,
    }
  },
  name: "EditProfile",
  components: {
    // NDynamicTags,
    // NAutoComplete,
    NButton,
    NForm,
    NInput,
    NFormItem,
    NSelect
  },
  methods: {
    upload: function (event: any) {
      this.form.append('photo_url', event.target.files[0])

      this.original_image = this.artist!.photo_url || ''
      this.artist!.photo_url = URL.createObjectURL(event.target.files[0])
      this.has_changed = true
    },
    reset: function () {
      (document.getElementById('profile-image') as HTMLFormElement).value = ""
      this.form.delete('photo_url')

      this.has_changed = false
      this.artist!.photo_url = this.original_image
    },
    back: function () {
      this.router.push('/profil')
    },
    call: function () {
      this.form.append('user.full_name', this.artist!.user.full_name!)
      this.form.append('user.description', this.artist!.user.description!)      

      this.form.append('aliasname', this.artist!.aliasname!)
      this.form.append('address', this.artist!.address!)
      this.form.append('phone_number', this.artist!.phone_number!)
      this.form.append('website', this.artist!.website!)

      if (!artistActiveStatus || this.artist!.portfolio_url_reg)
        this.form.append('portfolio_url_reg', this.artist!.portfolio_url_reg!)

      this.artist!.categories.forEach((cat) => {
        this.form.append('categories', cat)
      })
      this.artist!.cities.forEach((city) => {
        this.form.append('cities', city)
      })

      // FOR DEBUG
      // for (var f of this.form.entries()) {
      //   console.log(f)
      // }
      
      this.submitLoading = true
      axios.put('/api/profile/', this.form, {headers: {Authorization: `Bearer ${store.state.token}`}}
      ).then(
          response => {
            this.message.success("Profile is successfully updated")
            this.submitLoading = false
            store.commit("setPhotoUrl", response.data.photo_url)
            this.router.push('/profil')
        }).catch(error => {
          console.log(error)
          this.submitLoading = false
          this.message.error("A problem is occurred when updating portfolio", {showIcon: true, closable: true})
      })

    }

  },
});
</script>

<style lang="sass" scoped>
$card-size: _(800px)
$card-pad: _(30px)

.ganti-button
  @include round(1)
  @include noselect
  @include shade
  width: fit-content
  padding: 9px 18px
  background-color: $clr-brand
  color: $clr-white
  font-size: 11px
  font-weight: 600
  transition: transform .2s

.ganti-button:hover
  transform: scale(1.05)

#kembali-button
  background-color: $clr-white
  color: $clr-brand

.card-content
  @include row
  width: 100%
  min-height: $card-size - (2 * $card-pad)
  align-items: stretch
  @media (max-width: 900px)
    flex-direction: column

.card-header
  @include col
  @include pad(_(20px))
  width: 327px
  height: 100%
  justify-content: flex-start
  align-items: center
  @media (max-width: 900px)
    box-sizing: initial
    width: calc(100% - 20px)

.button-container
  display: flex
  gap: 10px

.card-gap
  height: _(46px)

.artist-pic
  @include square(160px)
  border-radius: 100%
  background-size: cover
  background-position: center
  overflow: hidden

.artist-name
  font-weight: 600
  text-align: center

.status
  @include round(1)
  @include noselect
  width: fit-content
  padding: 3px 15px
  background-color: $clr-brand
  border-radius: 100px
  color: $clr-white
  font-size: 11px
  font-weight: 600

.sep
  width: 2px
  height: 100%
  margin: 0 10px
  background-color: $clr-medium

.card-body
  width: 100%
  height: 100%

.card-body-content
  @include col
  min-height: $card-size - (4 * $card-pad)
  height: calc(100% - 2 * 27px)
  padding: _(30px) 0
  justify-content: space-between
  align-items: flex-end

.card-body-text
  @include pad(5px)
  width: 100%
  height: fit-content

.artist-info
  @include col
  gap: 2px
  margin-bottom: 10px

.artist-info span
  font-weight: bold

.artist-input
  border: 1px solid $clr-medium
  border-radius: 5px
  height: 20px
  padding: 5px

.artist-input-disabled
  border: 1px solid gray
  border-radius: 5px
  height: 20px
  padding: 5px

.artist-text-area
  min-height: 60px
  font-family: $font-main
  padding: 5px
  resize: vertical

.artist-tag
  @include row
  flex-wrap: wrap
  gap: 5px

.profile-tag
  @include round(1)
  @include noselect
  width: max-content
  padding: 3px 15px
  background-color: $clr-white
  border-radius: 100px
  color: $clr-brand
  border: 2px solid $clr-brand
  font-size: 11px
  font-weight: bolder

.reset-button
  @include round(1)
  @include shade
  width: fit-content
  background-color: $clr-white
  color: $clr-brand
  font-size: 12px
  font-weight: 600
  transition: transform .2s

.reset-button:hover
  transform: scale(1.05)
</style>

<style>
.tags-main[data-v-54d3a52e] {
  background: transparent !important;
  border: 1px solid #EE5F16;
  border-radius: 5px;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: flex-start;
}

.input-wrapper[data-v-54d3a52e] {
  align-self: center;
  height: 100%;
  position: relative;
  width: 200px;
}

input[type=text][data-v-54d3a52e] {
  border-radius: 0;
  border: 0;
  height: 100%;
  outline: 0;
  position: relative;
  background: transparent;
}

button[data-v-bb7ceecc] {
  border: 1px solid #FF8E54;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  display: flex;
  height: 1rem;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
  outline: none;
  width: 1rem;
  padding: 0;
  cursor: pointer;
}

.tag-edit-input[data-v-bb7ceecc] {
  width: 0;
  min-width: 100px;
  outline: 0;
  border: 0;
}

.tags-list-enter-active[data-v-acc9a86e],
.tags-list-leave-active[data-v-acc9a86e] {
  transition: all 0.2s;
}

.tags-list-enter[data-v-acc9a86e],
.tags-list-leave-to[data-v-acc9a86e] {
  opacity: 0;
}

.tags-container[data-v-acc9a86e] {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  vertical-align: middle;
  flex-wrap: wrap;
  gap: 5px;
}

.tag-container[data-v-bb7ceecc] {
  display: flex;
  width: max-content;
  padding: 4px 3px 4px 10px;
  background-color: white !important;
  border-radius: 100px;
  color: #FF8E54 !important;
  border: 2px solid #FF8E54;
  font-size: 11px;
  font-weight: bolder;
  align-items: center;
}

.icon-wrapper[data-v-1a43cbde] {

  width: 0.4rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper[data-v-1a43cbde] svg[data-v-1a43cbde] {
  width: 100%;
  height: 100%;
  fill: #FF8E54;
}

.suggestion-wrapper[data-v-54d3a52e] {
  max-height: 500px;
  min-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 2rem;
  width: 100%;
  z-index: 100;
  filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.4));
}

.suggestion-wrapper[data-v-54d3a52e].hidden[data-v-54d3a52e] {
  visibility: hidden;
}

.suggest-pane[data-v-f2c085e8] {
  border-radius: 0.2rem;
  list-style: none;
  margin: 0;
  outline: none;
  padding: 0;
  width: 100%;
}

.suggest-pane-item[data-v-f2c085e8] {
  background-color: white !important;
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 0.85rem;
  justify-content: flex-start;
  overflow: hidden;
  padding: 0.4rem 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.suggest-pane-item[data-v-f2c085e8] span[data-v-f2c085e8] {
  padding-left: 0.4rem;
  transition: transform .1s

}

.suggest-pane-item[data-v-f2c085e8] span[data-v-f2c085e8]:hover {
  transform: scale(1.05);
}

.suggest-pane-item[data-v-f2c085e8][data-v-f2c085e8]:hover {
  background: rgba(255, 255, 255, 0.2);
}

.suggest-pane-item[data-v-f2c085e8].selected[data-v-f2c085e8] {
  background: rgba(255, 255, 255, 0.2);
}

.check-alias-wrapper {
  display:flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 1.7em;
}
.check-alias-result {
  /* font-weight: 800; */
  /* font-size: 1rem; */
  font-size: 1em;
}
.check-alias-available {
  color: #228B22;
  color: #18a058;
}
.check-alias-unavailable {
  color: #FF0000;
  color: #B22222;
}
.n-form-item-blank {
  gap: 1em;
  align-items: stretch;
}
.aliasname-available-wrapper {
  margin-top: -1.6em;
  display:flex;
  justify-content: end;
  /* align-items: flex-end;
  align-content: flex-end; */
  /* width: 9rem; */
  height: 1.6em;
  
}
#aliasname-available-msg {
  /* margin-right: 1.5em; */
  /* flex: 1 1 9rem; */
  flex-basis: 9rem;
  /* background-color: grey; */
  text-align: center;
}
</style>