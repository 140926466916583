<template>
  <ul class="pagination">
    <li class="pagination-item">
      <button
        type="button"
        name="First"
        @click="onClickFirstPage()"
        :disabled="isInFirstPage"
        style="padding: 4px 2px"
      >
        <PageFirst style="font-size: 16px"/>
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        name="Previous"
        @click="onClickPreviousPage()"
        :disabled="isInFirstPage"
        style="padding: 4px 2px"
      >
        <ChevronLeft style="font-size: 16px"/>
      </button>
    </li>

    <!-- Visible Buttons Start -->

    <li
      v-for="page in pages"
      :key="page.name"
      class="pagination-item"
    >
      <button
        type="button"
        @click="onClickPage(page.name)"
        :disabled="page.isDisabled"
        :class="{ active: isPageActive(page.name) }"
      >
        {{ page.name }}
      </button>
    </li>

    <!-- Visible Buttons End -->

    <li class="pagination-item">
      <button
        type="button"
        name="Next"
        @click="onClickNextPage()"
        :disabled="isInLastPage"
        style="padding: 4px 2px"
      >
        <ChevronRight style="font-size: 16px"/>
      </button>
    </li>

    <li class="pagination-item">
      <button
        type="button"
        name="Last"
        @click="onClickLastPage()"
        :disabled="isInLastPage"
        style="padding: 4px 2px"
      >
        <PageLast style="font-size: 16px"/>
      </button>
    </li>
  </ul>
</template>
<script lang="ts">

import * as Vue from "vue";
import { PageFirst, ChevronLeft, ChevronRight, PageLast} from "mdue";

export default Vue.defineComponent({
  components: {
    PageFirst, ChevronLeft, ChevronRight, PageLast
  },
  props: ["maxVisibleButtons", "totalPages", "perPage", "currentPage"],
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage == 1) {
        return 1;
      }

      // When on the last page
      if (this.currentPage == this.totalPages) {
        if (this.maxVisibleButtons <= this.totalPages)
          return this.totalPages - this.maxVisibleButtons + 1;
        return 1;
      }

      // When inbetween
      if (this.maxVisibleButtons <= this.totalPages) {
        if (this.currentPage <= 3)
          return 1;
        else if ((this.totalPages - this.currentPage) < 3)
          return this.totalPages - this.maxVisibleButtons + 1;
        return this.currentPage - 2;
      }
      return this.currentPage - 1;
    },
    pages() {
      const range = [];

      for (
        let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++
      ) {
        range.push({
          name: i,
          isDisabled: i == this.currentPage
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage == 1;
    },
    isInLastPage() {
      return this.currentPage == this.totalPages;
    },
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1);
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1);
    },
    onClickPage(page: any) {
      this.$emit('pagechanged', page);
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages);
    },
    isPageActive(page: any) {
      return this.currentPage == page;
    }
  }
});
</script>

<style lang="sass" scoped>
  .pagination
    margin-bottom: 0
    display: flex
    align-items: center
    gap: 4px
    list-style-type: none
    padding: 0

    @media (max-width: 900px)
      margin: 0

  .pagination-item
    display: inline-block

  .pagination-item > button
    padding: 5px

  .active
    background-color: $clr-brand
    color: $clr-white
</style>