<template>
    <div></div>
</template>

<script setup lang="ts">
import { getCurrentInstance } from "@vue/runtime-core";
import { useMessage } from "naive-ui";

const app = getCurrentInstance()
app!.appContext.config.globalProperties.message = useMessage()
</script>