<template>
  <div id="container" v-click-outside="hide" v-if="!store.state.isMobile">
    <button id="toggle" @click="toggle" :class="{ active: isActive }">
      <div class="photo"
           :style="{'background-image': 'url('+ (store.state.photoUrl || placeholderImg) +')'}"/>
    </button>
    <div class="dashboard-container" :class="{ opened: isActive }">
      <button class="dashboard-button">
        <div class="photo"
             :style="{'background-image': 'url('+ (store.state.photoUrl || placeholderImg) +')'}"/>
        <div style="text-align:left">
          <p>{{ store.state.email }}</p>
          <p id="small">{{ isAdmin ? "ADMIN" : "ARTIST" }}</p>
        </div>
      </button>
      <button class="dashboard-button" id="logout" @click="logout">
        <LogoutVariant style="font-size: 32px"/>
        <p>Log out</p>
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import * as Vue from 'vue';
import {ChevronDown, LogoutVariant} from "mdue";
import vClickOutside from 'click-outside-vue3';
import store from '@/store/index.js'

export default Vue.defineComponent({
  components: {
    ChevronDown,
    LogoutVariant,
  },
  data() {
    return {
      isActive: false,
      store,
      brandImg: require('../assets/img/brand.png'),
      placeholderImg: require('../assets/img/photo-placeholder.png')
    };
  },
  methods: {
    logout() {
      this.store.commit('removeToken')
      this.store.commit('removeRefresh')
      this.$router.push({name: 'login'})
    },
    toggle() {
      this.isActive = !this.isActive;
    },
    hide() {
      this.isActive = false
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  computed: {
    isAdmin() {
      return store.state.isAdmin
    }
  }
})
</script>

<style lang="sass" scoped>


#toggle
  padding: 5px
  @include row(_(0px))
  border-radius: 200px

.active
  background: $clr-brand
  color: $clr-white

#container
  position: relative
  display: inline-block

.dashboard-container
  @include shade
  @include round(1)
  overflow: hidden
  background: $clr-white
  color: $clr-dark
  width: _(400px)
  position: absolute
  right: 0
  margin-top: 0
  display: block
  transition: 0.1s
  height: 0

.opened
  margin-top: _(50px)
  height: 102px

.dashboard-button
  @include pad(_(15px))
  @include fit
  @include row
  border-radius: 0
  height: fit-content
  box-sizing: border-box
  color: inherit

  &:hover
    color: $clr-white
    background-color: $clr-medium

  &:active
    background-color: $clr-dark


</style>