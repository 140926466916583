<template>
    <div class="container">
        <div class="card">
                <n-space vertical align="center">
                  <h3>Edit Portfolio</h3>
                </n-space>
            <n-form ref="form" :model="model" :rules="formRules">
                <n-grid :cols="2" :x-gap="20" :y-gap="16" item-responsive responsive="screen">
                    <n-grid-item span="2 s:1">
                        <n-form-item path="title" label="Title">
                            <n-input
                                id="title"
                                v-model:value="model.title"
                                @keydown.enter.prevent
                                size="large"
                            />
                        </n-form-item>
                        <div v-if="model.description">
                            Description
                            <QuillEditor
                                theme="snow"
                                content-type="html"
                                v-model:content="model.description"
                            />
                        </div>
                    </n-grid-item>
                    <n-grid-item span="2 s:1">
                        <n-form-item label="Portfolio Type">
                            <n-tabs ref="tabs" default-value="text" v-model:value="tabsValue" justify-content="space-evenly" type="segment">
                                <n-tab-pane name="text" tab="Text"></n-tab-pane>
                                <n-tab-pane name="youtube" tab="YouTube">
                                    <n-grid :cols="1" :y-gap="8">
                                        <n-grid-item>
                                            <n-form-item first path="youtube_url" :show-label="false">
                                                <n-input
                                                    id="youtube_url"
                                                    v-model:value="model.youtube_url"
                                                    size="large"
                                                    @keydown.enter.prevent
                                                    placeholder="Enter URL video"
                                                />
                                            </n-form-item>
                                        </n-grid-item>
                                        <n-grid-item>
                                            <div class="auto-resizable-iframe">
                                                <n-icon size="128" :depth="5">
                                                    <Youtube />
                                                </n-icon>
                                                <div>
                                                    <iframe
                                                        title="Youtube Portfolio"
                                                        :src="ytEmbed"
                                                        v-if="ytEmbed"
                                                        allowfullscreen="allowfullscreen"
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </n-grid-item>
                                    </n-grid>
                                </n-tab-pane>
                                <n-tab-pane name="audio" tab="Audio">
                                    <n-form-item :show-label="false">
                                        <n-upload
                                            ref="formAudio"
                                            @change="handleAudioChange"
                                            class="upload-form"
                                            :file-list="[]"
                                        >
                                            <n-upload-dragger>
                                                <div v-if="audioFile || audioFileUrl">
                                                    <n-button
                                                        text
                                                        class="close-btn"
                                                        @click="closeAudioPreview"
                                                    >
                                                        <template #icon>
                                                            <n-icon>
                                                                <WindowClose />
                                                            </n-icon>
                                                        </template>
                                                    </n-button>
                                                    <audio
                                                        controls
                                                        :src="audioFileUrl"
                                                    ></audio>
                                                </div>
                                                <div v-if="!(audioFile || audioFileUrl)">
                                                    <div>
                                                        <n-icon size="48" :depth="3">
                                                            <FileUploadOutline />
                                                        </n-icon>
                                                    </div>
                                                    <n-text style="font-size: 16px">
                                                        Drag file or click to upload
                                                    </n-text>
                                                    <n-text :type="audioFileFeedback ? 'error' : ''" depth="3" style="margin: 8px 0 0 0">
                                                        {{ audioFileFeedback || "Max size is " + maxAudioSize/(1024**2) + "MB. Supported formats are MP3, WAV, FLAC, and AAC." }}
                                                    </n-text>
                                                </div>
                                            </n-upload-dragger>
                                        </n-upload>
                                    </n-form-item>
                                </n-tab-pane>
                                <n-tab-pane name="image" tab="Picture">
                                    <n-form-item :show-label="false">
                                        <n-upload
                                            ref="formImage"
                                            @change="handleImageChange"
                                            class="upload-form"
                                            :file-list="[]"
                                        >
                                            <n-upload-dragger>
                                                <div v-if="imageFile || imageFileUrl">
                                                    <n-button
                                                        text
                                                        class="close-btn"
                                                        @click="closeImagePreview"
                                                    >
                                                        <template #icon>
                                                            <n-icon>
                                                                <WindowClose />
                                                            </n-icon>
                                                        </template>
                                                    </n-button>
                                                    <img
                                                        :src="imageFileUrl"
                                                        alt=""
                                                    >
                                                </div>
                                                <div v-if="!(imageFile || imageFileUrl)">
                                                    <div>
                                                        <n-icon size="48" :depth="3">
                                                            <FileUploadOutline />
                                                        </n-icon>
                                                    </div>
                                                    <n-text style="font-size: 16px">
                                                        Drag file or click to upload
                                                    </n-text>
                                                    <n-text :type="imageFileFeedback ? 'error' : ''" depth="3" style="margin: 8px 0 0 0">
                                                        {{ imageFileFeedback || "Max size is " + maxImageSize/(1024**2) + "MB. Supported formats are JPEG, PNG, WebP, and SVG." }}
                                                    </n-text>
                                                </div>
                                            </n-upload-dragger>
                                        </n-upload>
                                    </n-form-item>
                                </n-tab-pane>
                                <n-tab-pane name="video" tab="Video">
                                    <n-form-item :show-label="false">
                                        <n-upload
                                            ref="formVideo"
                                            @change="handleVideoChange"
                                            class="upload-form"
                                            :file-list="[]"
                                        >
                                            <n-upload-dragger>
                                                <div v-if="videoFile || videoFileUrl">
                                                    <n-button
                                                        text
                                                        class="close-btn"
                                                        @click="closeVideoPreview"
                                                    >
                                                        <template #icon>
                                                            <n-icon>
                                                                <WindowClose />
                                                            </n-icon>
                                                        </template>
                                                    </n-button>
                                                    <video z-index="100" class="video-modal"  controls preload="false" 
                                                        crossorigin="anonymous" 
                                                        cors="anonymous" data-setup='{}' 
                                                        style="">
                                                        <source ref="videoSource" :src="videoFileUrl" />
                                                        <!-- <p class="vjs-no-js">To view this video please enable JavaScript</p> -->
                                                    </video>
                                                </div>
                                                <div v-if="!(videoFile || videoFileUrl)">
                                                    <div>
                                                        <n-icon size="48" :depth="3">
                                                            <FileUploadOutline />
                                                        </n-icon>
                                                    </div>
                                                    <n-text style="font-size: 16px">
                                                        Drag file or click to upload
                                                    </n-text>
                                                    <n-text :type="videoFileFeedback ? 'error' : ''" depth="3" style="margin: 8px 0 0 0">
                                                        {{ videoFileFeedback || "Max size is " + maxVideoSize/(1024**2) + "MB. Supported formats are JPEG, PNG, WebP, and SVG." }}
                                                    </n-text>
                                                </div>
                                            </n-upload-dragger>
                                        </n-upload>
                                    </n-form-item>
                                </n-tab-pane>
                            </n-tabs>
                        </n-form-item>
                    </n-grid-item>
                </n-grid>
                <n-space justify="end">
                    <n-button
                        id="backBtn"
                        @click="back"
                        size="large"
                    >
                        Cancel
                    </n-button>
                    <n-button
                        :loading="submitLoading"
                        icon-placement="left"
                        id="submitBtn"
                        type="primary"
                        attr-type="submit"
                        @click="handleSubmit"
                        size="large"
                    >
                        <strong>Update</strong>
                    </n-button>
                </n-space>
            </n-form>
        </div>
    </div>
</template>

<script setup lang="ts">
import store from "@/store"
import { Portfolio } from "@/types/Portfolio"
import axios from 'axios'
import { AxiosError } from 'axios'
import { FormInst, NForm, NFormItem, NInput, NTabs,
        NTabPane, NUpload, NUploadDragger, UploadInst,
        NGrid, NGridItem, NConfigProvider, NButton, NSpace,
        NImage, NUploadTrigger, NIcon, NText, NP, FormRules,
        FormItemRule } from "naive-ui"
import { ValidateError } from 'async-validator'
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue"
import { WindowClose, FileUploadOutline, Youtube } from 'mdue';
import { useRouter } from "vue-router"
import { QuillEditor } from "@vueup/vue-quill";

const props = defineProps<{
    id?: number
}>()

const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message
const maxAudioSize = ref(app!.appContext.config.globalProperties.maxAudioSize)
const maxAudioSizeMB = (maxAudioSize.value/(1024**2)).toFixed()
const maxImageSize = ref(app!.appContext.config.globalProperties.maxImageSize)
const maxImageSizeMB = (maxImageSize.value/(1024**2)).toFixed()
const maxVideoSize = ref(app!.appContext.config.globalProperties.maxVideoSize)
const maxVideoSizeMB = (maxVideoSize.value / (1024 ** 2)).toFixed()
const router = useRouter()
const tabs = ref(null)
const tabsValue = ref('')
const model = ref<Portfolio>({} as Portfolio)
axios.get("/api/portfolio/" + props.id)
    .then(resp => {
        model.value = resp.data
        let type = model.value!.type.toLowerCase()
        console.log(type)
        let file_url = model.value!.file_url || ''
        audioFileUrl.value = type == "audio" ? file_url : ""
        imageFileUrl.value = type == "image" ? file_url : ""
        videoFileUrl.value = type == "video" ? file_url : ""
        console.log(videoFileUrl.value)
        // sudo apt-get install ca-certificates curl gnupg
        tabsValue.value = type
    })
    .catch(error => {
        console.error(error)
        message.error("Failed loading portfolio", {showIcon: true, closable: true})
    })

// Title, Description, YouTube
const form = ref<FormInst | null>(null)
const formRules: any = {
    title: [
        {
            key: "title",
            required: true,
            message: 'Title cannot be empty',
            trigger: ['change']
        }
    ],
    youtube_url: [
        {
            key: "youtube_url",
            required: true,
            message: "URL cannot be empty",
            trigger: ['input']
        },
        {
            key: "youtube_url",
            validator: (rule: FormItemRule, value: any) => {
                return !!ytEmbed.value
            },
            message: "URL is invalid",
            trigger: ['input']
        }
    ],
}
const ytrx = new RegExp("^.*(?:(?:youtu\\.be\\/|v\\/|vi\\/|u\\/\\w\\/|embed\\/)|(?:(?:watch)?\\?v(?:i)?=|\\&v(?:i)?=))([^#\\&\\?]*).*")
function getYoutubeId(url: string | null | undefined) {
    if (!url)
        return null
    const res = url.match(ytrx)
    return res ? res[1] : ""
}
const ytEmbed = computed(() => {
    const ytId = getYoutubeId(model.value!.youtube_url)
    return ytId ? `https://youtube.com/embed/${ytId}` : null
})

//Audio File
//https://en.wikipedia.org/wiki/HTML5_audio
const audioTypes = ["audio/wav", "audio/mpeg", "audio/aac", "audio/flac"]
const audioFile = ref<File | null>(null)
const audioFileFeedback = ref<string | null>()
const audioFileUrl = ref<string | null>(null)
function validateAudio(file: File) {
    if (!audioTypes.includes(file.type)) {
        return "Unsupported format. Supported formats are MP3, WAV, FLAC, and AAC."
    }
    if (file.size > maxAudioSize.value) {
        return `File size must not exceed ${maxAudioSizeMB}MB.`
    }
    return null
}
function handleAudioChange(data: any) {
    const file = data.file.file

    audioFile.value = null
    audioFileUrl.value = null
    audioFileFeedback.value = null

    const feedback = validateAudio(file)
    if (feedback) {
        audioFileFeedback.value = feedback
        return
    }

    audioFile.value = file
    audioFileUrl.value = URL.createObjectURL(file)
}
function closeAudioPreview(e: PointerEvent) {
    e.preventDefault()
    e.stopPropagation()
    audioFile.value = null
    audioFileUrl.value = null
    audioFileFeedback.value = null
}

//Image File
const imageTypes = ["image/png", "image/svg+xml", "image/jpeg", "image/webp"]
const imageFile = ref<File | null>(null)
const imageFileFeedback = ref<string | null>()
const imageFileUrl = ref<string | null>('')
function validateImage(file: File) {
    if (!imageTypes.includes(file.type)) {
        return "Unsupported format. Supported formats are JPEG, PNG, WebP, and SVG."
    }
    if (file.size > maxImageSize.value) {
        return `File size must not exceed ${maxImageSizeMB}MB.`
    }
    return null
}

function handleImageChange(data: any) {
    const file = data.file.file

    imageFile.value = null
    imageFileUrl.value = null
    imageFileFeedback.value = null

    const feedback = validateImage(file)
    if (feedback) {
        imageFileFeedback.value = feedback
        return
    }

    imageFile.value = file
    imageFileUrl.value = URL.createObjectURL(file)
}
function closeImagePreview(e: PointerEvent) {
    e.preventDefault()
    e.stopPropagation()
    imageFile.value = null
    imageFileUrl.value = null
    imageFileFeedback.value = null
}
// Video File
const videoTypes = ["video/mp4", "video/quicktime", "video/x-matroska", "video/x-msvideo"]
const videoFile = ref<File | null>(null)
const videoFileFeedback = ref<string | null>()
const videoFileUrl = ref<string | null>('')

function validateVideo(file: File) {
    if (!videoTypes.includes(file.type)) {
        return "Unsupported format. Supported formats are JPEG, PNG, WebP, and SVG."
    }
    if (file.size > maxVideoSize.value) {
        return `File size must not exceed ${maxVideoSizeMB}MB.`
    }
    return null
}

function handleVideoChange(data: any) {
    const file = data.file.file

    videoFile.value = null
    videoFileUrl.value = null
    videoFileFeedback.value = null

    const feedback = validateVideo(file)
    if (feedback) {
        videoFileFeedback.value = feedback
        return
    }

    videoFile.value = file
  videoFileUrl.value = URL.createObjectURL(file)
}
function closeVideoPreview(e: PointerEvent) {
    e.preventDefault()
    e.stopPropagation()
  videoFile.value = null
  videoFileUrl.value = null
  videoFileFeedback.value = null
}

// General

function back() {
    router.push("/portfolio")
}

const submitLoading = ref(false)
async function handleSubmit(e: MouseEvent) {
    e.preventDefault()
    let err: ValidateError[][] = []
    await form.value!.validate(
        undefined,
        rule => {
            return (rule as any).key == "title"
        }
    ).catch(errors => err = err.concat(errors))

    let title = model.value!.title
    let description = model.value!.description
    let type = (tabs.value as any).mergedValue
    let youtube_url = ""
    let file_url: File | string = "";
    switch(type) {
        case "youtube":
            await form.value!.validate(
                undefined,
                rule => {
                    return (rule as any).key == "youtube_url"
                }
            ).catch(errors => err = err.concat(errors))
            youtube_url = ytEmbed.value || ""
            break
        case "audio":
            if (!audioFile.value && !audioFileUrl.value) {
                audioFileFeedback.value = "File cannot be empty"
            }
            if (audioFileFeedback.value) {
                err.push([{message: audioFileFeedback.value}])
                break
            }
            file_url = (audioFile.value || audioFileUrl.value) as any
            break
        case "image":
            if (!imageFile.value && !imageFileUrl.value) {
                imageFileFeedback.value = "File cannot be empty"
            }
            if (imageFileFeedback.value) {
                err.push([{message: imageFileFeedback.value}])
                break
            }
            file_url = (imageFile.value || imageFileUrl.value) as any
            break
        case "video":
            if (!videoFile.value && !videoFileUrl.value) {
                videoFileFeedback.value = "File cannot be empty"
            }
            if (videoFileFeedback.value) {
                err.push([{message: videoFileFeedback.value}])
                break
            }
            file_url = (videoFile.value || videoFileUrl.value) as any
            break
    }

    if (err.length) {
        message.error("Form is not filled correctly", {showIcon: true, closable: true})
        return
    }

    let formData = new FormData()
    formData.append("type", type.charAt(0).toUpperCase() + type.slice(1))
    formData.append("title", title)
    formData.append("description", description as any)
    formData.append("youtube_url", youtube_url as any)
    if (file_url != model.value.file_url)
        formData.append("file_url", file_url)
    submitLoading.value = true
    axios.patch("/api/portfolio/" + props.id, formData)
    .then(val => {
        message.success("Portfolio is successfully updated!", {showIcon: true, closable: true})
        router.push("/portfolio")
    }).catch((err: AxiosError) => {
        message.error("A problem is occurred when updating portfolio", {showIcon: true, closable: true})
        console.error(err)
    }).finally(() => {
        submitLoading.value = false
    })
}
</script>

<style lang="sass" scoped>
.n-upload-dragger > div
    display: flex
    flex-direction: column
    justify-content: center
    height: 17em
    padding: 2em
    position: relative
.close-btn
    position: absolute
    right: 0px
    top: 0px
    z-index: 100
    padding: 6px

.n-upload-dragger
    position: relative
    padding: 0px

.auto-resizable-iframe .n-icon
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
.auto-resizable-iframe
    position: relative
    max-width: 900px
    margin: 0px auto
    background-color: #FBEEE6
    border-radius: 2px
.auto-resizable-iframe > div
    position: relative
    padding-bottom: 50%
    height: 0px
.auto-resizable-iframe iframe
    border: 0
    position: absolute
    top: 0px
    left: 0px
    width: 100%
    height: 100%

.n-form
    position: relative

img
    width: 100%
    height: 100%
    object-fit: contain
audio
    max-height: 100%
    max-width: 100%
    margin: auto
    object-fit: contain

.video-modal
  width: 100%    !important
  height: 100%   !important
</style>