<template>
  <div id="schedule-grid">
    <div id="schedule-block">
      <n-space vertical align="center">
        <h3>List of Availability & Appointment</h3>
      </n-space>
      <modal ref="modal">
        <template v-slot:trigger>
          <div v-show="editable" class="service" id="tambah" @click="handleModalOnClick">
            <PlusBox style="font-size: 32px"></PlusBox>
            Add new schedule
          </div>
        </template>
        <template v-slot:content>
          <n-form ref="form" :model="model" :rules="rules">
            <n-form-item id="startTimeForm" path="start_time" label="Start Time">
              <n-date-picker
                  placeholder="Select Date and Time"
                  v-model:value="model.start_time"
                  type="datetime"
              />
            </n-form-item>
            <n-form-item id="endTimeForm" path="end_time" label="End Time">
              <n-date-picker
                  placeholder="Select Date and Time"
                  v-model:value="model.end_time"
                  type="datetime"
              />
            </n-form-item>
            <n-form-item id="typeForm" path="type" label="Schedule Type">
              <n-select
                  placeholder="Select Schedule Type"
                  v-model:value="model.type"
                  :options='scheduleTypeOptions'
                  filterable
                  clearable
                  remote
                  @keydown.enter.prevent
                  @search='scheduleTypeSearch'
                  size="large"
              />
            </n-form-item>
            <div v-if="model.type === 'Appointment'">
              Description
              <QuillEditor
                  theme="snow"
                  content-type="html"
                  v-model:content="model.description"
                  placeholder="Description"
              />
            </div>
            <n-button
                id="submitBtn" type="primary" attr-type="submit"
                size="large" @click="handleSubmitButtonClick" :loading="submitLoading"
            >
              <strong>Submit</strong>
            </n-button>
          </n-form>
        </template>
      </modal>
      <ScheduleList
          v-if="loaded"
          :schedules="schedule"
          :key="scheduleKey"
          :editable="editable"
          @schedule-delete="onScheduleDelete"
      />
    </div>
    <div id="calendar-block">
      <Calendar
          style="height: 100%"
          :schedules="schedule"
      />

    </div>
  </div>

</template>

<script setup lang="ts">
import {
  FormInst, FormRules, NForm, NFormItem,
  NButton, NSpace, SelectOption, NDatePicker,
  NSelect, FormItemRule
} from "naive-ui"
import {PlusBox} from "mdue";
import ScheduleList from "@/components/ScheduleList.vue"
import Modal from "@/components/Modal.vue";
import {QuillEditor} from "@vueup/vue-quill";
import {ref} from "@vue/runtime-core";
import {useRouter} from "vue-router"
import {ScheduleModel} from "@/types/ScheduleModel";
import Calendar from "@/components/Calendar.vue"
import axios, {AxiosError} from "axios";
import {Schedule, ScheduleDto, ScheduleTypeDto} from "@/types/Schedule";
import {ValidateError} from "async-validator";
import {getCurrentInstance} from "vue";


// Initialize router
const router = useRouter();

const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message

const form = ref<FormInst | null>(null)
const model = ref<Schedule>({} as Schedule)
model.value.description = ''
const modal = ref(null)

let submitLoading = ref(false)
const scheduleKey = ref(1)

const schedule = ref<ScheduleModel[]>([])
const loaded = ref(false)
const currentPage = ref(1)

var scheduleTypes: SelectOption[] = []
const scheduleTypeOptions = ref<SelectOption[]>([])

const props = defineProps<{
  url?: string,
  editable?: boolean
}>()

const rules: FormRules = {
  start_time: [{
    required: true,
    type: 'number',
    message: "Start Time is required",
    trigger: ["change"]
  }],
  end_time: [{
    required: true,
    type: 'number',
    validator(rule: FormItemRule, value: Date) {
      if (!value) {
        return new Error('End Time is required')
      } else if (value < model.value.start_time) {
        return new Error('End Time must not be earlier than Start Time')
      }
    },
    trigger: ["change"]
  }],
  type: [{
    required: true,
    message: "Schedule Type is required",
    trigger: ["change"]
  }],
}


getSchedule(currentPage.value)

async function getSchedule(page: any) {
  await axios.get(props.url + `?page=${page}`)
      .then(response => {
        schedule.value = response.data.results
        loaded.value = true
        scheduleKey.value *= -1;
      })
      .catch(error => console.log(error))
}

async function handleSubmit(errors: ValidateError[][] | undefined) {
  if (!errors) {
    let payload = new ScheduleDto(model.value);
    submitLoading.value = true
    axios.post("/api/schedule/", payload)
        .then(_val => {
          message.success("Schedule is successfully added!", {showIcon: true, closable: true});
          getSchedule(currentPage.value);
          submitLoading.value = false;
          (modal.value as any).showModal = false;
          (model.value as any) = {};
        })
        .catch((_err: AxiosError) => {
          submitLoading.value = false;
          message.error("A problem is occurred when updating schedule", {showIcon: true, closable: true});
        })
  }
}

function handleModalOnClick() {
  axios.get("/api/schedule/type").then(response => {
    scheduleTypes = response.data.map((i: ScheduleTypeDto) => {
      return {value: i.name, label: i.name}
    })
    scheduleTypeOptions.value = scheduleTypes
  }).catch((err: AxiosError) => {
    message.error("Cannot load list of schedule types", {showIcon: true, closable: true});
  })
}

function scheduleTypeSearch(query: string) {
  if (!query.length) {
    scheduleTypeOptions.value = scheduleTypes
  } else {
    var res: SelectOption[] = scheduleTypes.filter((item) => ~item.value.toString().toLowerCase().indexOf(query.toLowerCase()))
    scheduleTypeOptions.value = res
  }
}

function handleSubmitButtonClick(e: MouseEvent) {
  e.preventDefault();
  form.value!.validate(handleSubmit).catch(_error => {
    message.error("Form is invalid", {showIcon: true, closable: true})
  })
}

function onScheduleDelete() {
  getSchedule(currentPage.value)
}

</script>

<style lang="sass" scoped>
#schedule-grid
  height: auto
  display: grid
  grid-template-columns: 30% 70%
  grid-gap: 20px
  @media (max-width: 900px)
    grid-template-columns: 100%

#schedule-block
  display: flex
  flex-direction: column
  gap: 5px
</style>