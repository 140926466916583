import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

import ListProfile from "@/views/ListProfile.vue";
import ArtistRegistration from "@/views/ArtistApproval.vue";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import ArtistProfile from "@/views/ProfileArtist.vue";
import ArtistService from "@/views/ServiceArtist.vue";
import DetailProfil from "@/views/DetailProfil.vue";
import EditProfile from "@/views/EditProfile.vue";
import PortfolioArtist from "@/views/PortfolioArtist.vue";
import SubmitPortfolio from "@/views/SubmitPortfolio.vue";
import EditPortfolio from "@/views/EditPortfolio.vue";
import EditService from "@/views/EditService";
import AddService from "@/views/AddService.vue";
import EmailVerification from "@/views/EmailVerification.vue";
import ForgetPassword from "@/views/ForgetPassword.vue";
import ScheduleArtist from "@/views/ScheduleArtist.vue"

const routes = [
    {
        path: '/',
        name: 'home',
        component: ListProfile,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/artists-list',
        name: 'artists-list',
        component: ListProfile,
        meta: {
            requireLogin: true,
            requireAdmin: true
        }
    },
    {
        path: '/artists-registration',
        name: 'registrasi',
        component: ArtistRegistration,
        meta: {
            requireLogin: true,
            requireAdmin: true
        }
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            secure: true
        }
    },
    {
        path: '/logout',
        redirect: to => {
            store.commit('removeToken')
            store.commit('removeRefresh')
            return { path: '/login' }
        }
    },
    {
        path: '/register',
        name: 'register',
        component: Register,
        meta: {
            secure: true
        }
    },
    {
        path: '/verify-email/:token?',
        name: 'verify-email',
        component: EmailVerification,
        props: true,
    },
    {
        path: '/forget-password/:token?',
        name: 'forget-password',
        component: ForgetPassword,
        props: true,
    },
    {
        path: '/profil',
        name: 'profil',
        component: ArtistProfile,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },

    {
        path: '/portfolio',
        name: 'portfolio',
        component: PortfolioArtist,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },

    {
        path: '/service',
        name: 'service',
        component: ArtistService,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },

    {
        path: '/detailprofil/:id',
        name: 'detailprofil',
        component: DetailProfil,
        props: true,
        meta: {
            requireLogin: true
        }
    },
    {
        path: '/profil/edit',
        name: 'editprofile',
        component: EditProfile,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },
    {
        path: '/portfolio/submit',
        name: 'submitportfolio',
        component: SubmitPortfolio,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },
    {
        path: '/portfolio/edit/:id',
        name: 'editportfolio',
        component: EditPortfolio,
        props(route) {
            const props = { ...route.params }
            props.id = +props.id
            return props
        },
    },
    {
        path: '/service/edit/:id',
        name: 'editservice',
        component: EditService,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },
    {
        path: '/service/add',
        name: 'addservice',
        component: AddService,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    },
    {
        path: '/schedule',
        name: 'schedule',
        component: ScheduleArtist,
        meta: {
            requireLogin: true,
            requireArtist: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})

export function beforeEach(to, from, next) {
    if (to.matched.some(record => record.meta.requireLogin) && !store.state.isAuthenticated) {
        next({ name: 'login' });
    } else if (to.matched.some(record => record.meta.requireLogin) && !store.state.isEmailVerified) {
        next({ name: 'verify-email' })
    } else if (to.matched.some(record => record.meta.secure) && store.state.isAuthenticated && store.state.isAdmin) {
        next({ name: 'artists-list' })
    } else if (to.matched.some(record => record.meta.secure) && store.state.isAuthenticated && !store.state.isAdmin) {
        next({ name: 'profil' })
    } else if (to.matched.some(record => record.meta.requireArtist) && store.state.isAuthenticated && store.state.isAdmin) {
        next({ name: 'artists-list' })
    } else if (to.matched.some(record => record.meta.requireAdmin) && store.state.isAuthenticated && !store.state.isAdmin) {
        next({ name: 'profil' })
    } else {
        next()
    }
}

router.beforeEach((to, from, next) => beforeEach(to, from, next))

export default router