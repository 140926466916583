import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createBlock(_component_router_link, _mergeProps(_ctx.$props, {
    class: { mobile: _ctx.store.state.isMobile },
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('test-click')))
  }), {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["navtab-div", { pop: _ctx.pop() }])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ]),
    _: 3
  }, 16, ["class"]))
}