import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d040709e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_n_modal = _resolveComponent("n-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showModal = true)),
      class: "modal-trigger"
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
    ]),
    _createVNode(_component_n_modal, {
      show: _ctx.showModal,
      "onUpdate:show": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.showModal) = $event)),
      "mask-closable": true
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "content", {}, undefined, true)
        ])
      ]),
      _: 3
    }, 8, ["show"])
  ]))
}