<template>
  <div>
    <div @click="showModal = true" class="modal-trigger">
      <slot name="trigger"></slot>
    </div>
    <n-modal
        v-model:show="showModal"
        :mask-closable="true"
    >
      <div class="card">
        <slot name="content"></slot>
      </div>
    </n-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { NModal } from 'naive-ui'

export default defineComponent({
  name: "Modal",
  setup () {
    const showModalRef = ref(false)

    return {
      showModal: showModalRef,
    }
  },
  components: {
    NModal
  }
})

</script>

<style lang="sass" scoped>
.modal-trigger
  background: transparent
  transition: 0.2s
  &:hover
    cursor: pointer
  &:active
    filter: brightness(0.95)

.card
  width: 60%
  @media (max-width: 900px)
    width: 100%
    margin: 10px
    max-height: 60vh
    overflow-y: scroll
    overflow-x: hidden
    @include round(1)
</style>