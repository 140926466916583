<template>
  <router-link v-bind="$props" :class="{ mobile: store.state.isMobile }" @click="$emit('test-click')">
    <div class="navtab-div" :class="{ pop: pop() }">
      <slot />
    </div>
  </router-link>
</template>

<script lang="ts">
import * as Vue from "vue";
import store  from "@/store/index";


export default Vue.defineComponent({
  name: 'NavTab',
  inheritAttrs: false,
  data () {
    return {
      store,
    }
  },
  props: ['to'],
  methods: {
    pop() {
      return window.location.href.startsWith(window.location.origin + this.to)
    }
  }
});
</script>

<style lang="sass" scoped>
.navtab-div
  font-weight: 600
  width: fit-content
  height: _(54px)
  justify-content: center
  @include pad(20px)
  @include row
  box-sizing: border-box
  transition: 0.2s
  &.pop
    color: $clr-medium

.mobile
  @include fit
  .navtab-div
    @include fit
    background-color: $clr-light
    color: $clr-dark
    border-top: 1px solid $clr-dark
    box-shadow: none
    border-radius: 0 0 0 0
    height: fit-content
    @include pad(15px)
    justify-content: left

  &:active > .navtab-div
    color: $clr-white
    background-color: $clr-dark
</style>