import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "card" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_profile_row = _resolveComponent("profile-row")!
  const _component_pagination = _resolveComponent("pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artists.results, (artist, idx) => {
        return (_openBlock(), _createBlock(_component_profile_row, {
          key: idx,
          approval: true,
          idx: artist.user.email,
          artist: artist
        }, null, 8, ["idx", "artist"]))
      }), 128)),
      _createVNode(_component_pagination, {
        maxVisibleButtons: 5,
        totalPages: Math.ceil(_ctx.artists.count/10) || 1,
        perPage: 10,
        currentPage: _ctx.currentPage,
        onPagechanged: _ctx.onPageChange
      }, null, 8, ["totalPages", "currentPage", "onPagechanged"])
    ])
  ]))
}