<template>
  <div class="container">
    <div class="card" style="min-height: fit-content; gap: 15px;">
      <n-form
          label-placement="top"
          :show-feedback=false
          >
        <n-form-item label="Who are you searching for?" :style="{width: '100%'}">
            <n-input
                placeholder="Search by name or email"
                v-model:value="searchQuery"
                v-on:keypress.enter.prevent="getData(1)"
                clearable
            />
        </n-form-item>
        <n-form-item id="statusDropdown" label="Status">
          <n-select
              v-model:value="status"
              :options="options.status"
          />
        </n-form-item>
        <button
          class="brand"
          v-on:click.prevent="getData(1)">
          Search
        </button>
      </n-form>
      <n-form
        label-placement="top"
        :show-feedback=false
        >
        <n-form-item id="sortDropdown" label="Sort By">
          <n-select
              v-model:value="sort"
              default-value="full_name"
              :options="options.sort"
              @update:value="getData(1)"
          />
        </n-form-item>
        <n-form-item id="orderDropdown" label="Order">
          <n-select
              v-model:value="order"
              :options="options.order"
              @update:value="getData(1)"
          />
        </n-form-item>
      </n-form>
    </div>
    <div class="card">
      <profile-row v-for="(artist, idx) in artists.results" :artist="artist" :idx="artist.user.email" :key="idx"/>
      <p v-if="!artists.count" :style="{'text-align': 'center'}">No Results</p>
      <pagination
          v-if="artists.count"
          :maxVisibleButtons="5"
          :totalPages="Math.ceil(artists.count/10) || 1"
          :perPage="10"
          :currentPage="currentPage"
          @pagechanged="getData"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "@vue/runtime-core";
import store from "@/store/index.js";
import Pagination from "@/components/Pagination.vue";
import ProfileRow from "@/components/ProfileRow.vue";
import {ProfileResponse} from "@/types/ProfileResponse";
import axios from "axios";
import {NForm, NFormItem, NInput, NSelect} from "naive-ui";
import {useRoute, useRouter} from "vue-router";

interface LooseObject {
    [key: string]: any
}

const artists = ref<ProfileResponse | null>({
  count: 1,
  next: null,
  previous: null,
  results: []
})

const route = useRoute()
const router = useRouter()
const currentPage = ref(route.query.page || 1)
const searchQuery = ref(route.query.search || '')
const status = ref(route.query.status || 'Any')
const sort = ref(route.query.sortby || 'full_name')
const order = ref(route.query.order || 'asc')
const wrapper = document.getElementById('wrapper')

getData(currentPage.value)

async function getData(page: any) {
  const query: LooseObject = {}

  page = isNaN(parseInt(page)) ? 1 : parseInt(page)
  currentPage.value = page;

  if (searchQuery.value !== '') {
    page = `${page}&search=${searchQuery.value}`
    query.search = (searchQuery.value as string)
  }

  if (status.value !== 'Any') {
    if (status.value === 'ACTIVE' || status.value === 'INACTIVE') {
      page = `${page}&status=${status.value === 'ACTIVE'}`
      query.status = status.value
    }
  }

  if (sort.value !== '') {
    if (sort.value === 'id' || sort.value === 'full_name' || sort.value === 'email') {
      page = `${page}&ordering=${order.value === 'desc' ? '-' : ''}user__${sort.value}`
      query.sortby = sort.value
      query.order = order.value
    }
  }

  if (currentPage.value > 1)
    query.page = currentPage.value

  await router.push({
    name: 'artists-list',
    query: query
  })

  await axios.get(`/api/profile/all?page=${page}`,
      {headers: {Authorization: `Bearer ${store.state.token}`}}
  ).then(
      response => {
        artists.value = response.data
      }).catch(
      error => {
        console.log(error)
        artists.value = {
          count: 0,
          next: null,
          previous: null,
          results: []
        }
      }
  )

  wrapper!.scroll({top: 0, behavior: 'smooth'})
}

const options = {
  status: [
    {
      label: "Any",
      value: 'Any',
    },
    {
      label: "ACTIVE",
      value: 'ACTIVE',
    },
    {
      label: "INACTIVE",
      value: 'INACTIVE',
    }
  ],
  sort: [
    {
      label: "Full Name",
      value: "full_name",
    },
    {
      label: "Email",
      value: "email",
    },
    {
      label: "Creation Date",
      value: "id",
    }
  ],
  order: [
    {
      label: "Ascending",
      value: "asc",
    },
    {
      label: "Descending",
      value: "desc",
    }
  ]
}
</script>

<style lang="sass" scoped>
  .n-form
    display: flex
    flex-direction: row
    align-items: flex-end
    gap: 20px

    @media (max-width: 900px)
      flex-direction: column

  #statusDropdown
    width: 30%!important

    @media (max-width: 900px)
      width: 100%!important

  #sortDropdown, #orderDropdown
    width: 50%!important

    @media (max-width: 900px)
      width: 100%!important

  :deep(.n-collapse-item) .n-collapse-item__content-inner
    padding-top: 10px!important

</style>