export default [
  "Ambon",
  "Balikpapan",
  "Banda Aceh",
  "Bandar Lampung",
  "Bandung",
  "Banjar",
  "Banjarbaru",
  "Banjarmasin",
  "Batam",
  "Batu",
  "Baubau",
  "Bekasi",
  "Bengkulu",
  "Bima",
  "Binjai",
  "Bitung",
  "Blitar",
  "Bogor",
  "Bontang",
  "Bukittinggi",
  "Cilegon",
  "Cimahi",
  "Cirebon",
  "Jakarta",
  "Denpasar",
  "Depok",
  "Dumai",
  "Gorontalo",
  "Gunungsitoli",
  "Jambi",
  "Jayapura",
  "Kediri",
  "Kendari",
  "Kotamobagu",
  "Kupang",
  "Langsa",
  "Lhokseumawe",
  "Lubuklinggau",
  "Madiun",
  "Magelang",
  "Makassar",
  "Malang",
  "Manado",
  "Mataram",
  "Medan",
  "Metro",
  "Mojokerto",
  "Padang",
  "Padang Panjang",
  "Padang Sidempuan",
  "Pagar Alam",
  "Palangkaraya",
  "Palembang",
  "Palopo",
  "Palu",
  "Pangkal Pinang",
  "Parepare",
  "Pariaman",
  "Pasuruan",
  "Payakumbuh",
  "Pekalongan",
  "Pekanbaru",
  "Pematangsiantar",
  "Pontianak",
  "Prabumulih",
  "Probolinggo",
  "Sabang",
  "Salatiga",
  "Samarinda",
  "Sawahlunto",
  "Sekayu",
  "Semarang",
  "Serang",
  "Sibolga",
  "Singkawang",
  "Solok",
  "Sorong",
  "Subulussalam",
  "Sukabumi",
  "Sungai Penuh",
  "Surabaya",
  "Surakarta",
  "Tangerang",
  "Tangerang Selatan",
  "Tanjungbalai",
  "Tanjungpinang",
  "Tarakan",
  "Tasikmalaya",
  "Tebing Tinggi",
  "Tegal",
  "Ternate",
  "Tidoro Kepulauan",
  "Tomohon",
  "Tual",
  "Yogyakarta",
];
