<template>
  <div class="schedule-card">
    <div v-for="schedule in scheduleGroups" :key="schedule.id" style="margin-bottom: 20px">
      <n-divider title-placement="left">
        {{ schedule.type }}
      </n-divider>
      <div v-for="(activity, i) in schedule.activity" :key="activity.id">
        <div style="margin-top: 3px; display: flex; padding: 10px">
          <div class="monospace" style="display: flex; flex-direction: column; flex-grow: 1">
            <span>{{ parseDateTime(new Date(activity.start_time)) }} -</span>
            <span>{{ parseDateTime(new Date(activity.end_time)) }}</span>
          </div>
          <div style="display: flex; flex-grow:0; flex-shrink: 1; flex-wrap: wrap">
            <modal :ref="el => modal[i] = el">
              <template v-slot:trigger>
                <n-tooltip>
                  <template #trigger>
                    <button class="with-icon clear"
                            @click="() => {currentModalI = i}">
                      <InformationOutline style="font-size: 24px"/>
                    </button>
                  </template>
                  Info
                </n-tooltip>
              </template>
              <template v-slot:content>
                <h3>
                  {{activity.type}}
                  {{ parseDateTime(new Date(activity.start_time)) }} -
                  {{ parseDateTime(new Date(activity.end_time)) }}
                </h3>
                <div v-html="activity.description || '<i>Schedule ini tidak memiliki deskripsi</i>'"></div>
              </template>
            </modal>
            <modal :ref="el => modal[i] = el">
              <template v-slot:trigger>
                <n-tooltip>
                  <template #trigger>
                    <button v-show="editable" class="with-icon clear" @click="prepareEditData(activity)">
                      <pencil-outline style="font-size: 24px"/>
                    </button>
                  </template>
                  Edit
                </n-tooltip>
              </template>
              <template v-slot:content>
                <n-form :ref="el => form[i] = el" :model="model" :rules="rules">
                  <n-form-item id="startTimeForm" path="start_time" label="Start Time">
                    <n-date-picker
                        placeholder="Select Date and Time"
                        v-model:value="model.start_time"
                        type="datetime"
                    />
                  </n-form-item>
                  <n-form-item id="endTimeForm" path="end_time" label="End Time">
                    <n-date-picker
                        placeholder="Select Date and Time"
                        v-model:value="model.end_time"
                        type="datetime"
                    />
                  </n-form-item>
                  <n-form-item id="typeForm" path="type" label="Tipe Schedule">
                    <n-select
                        v-model:value="model.type"
                        disabled
                        size="large"
                    />
                  </n-form-item>
                  <div v-if="model.type.toLowerCase() === 'appointment'">
                    Description
                    <QuillEditor
                        theme="snow"
                        content-type="html"
                        v-model:content="model.description"
                        placeholder="Description"
                    />
                  </div>
                  <n-button
                      id="submitBtn" type="primary" attr-type="submit"
                      size="large" @click="(e) => {currentModal = i; handleSubmitButtonClick(e, i)}"
                  >
                    <strong>Save</strong>
                  </n-button>
                </n-form>
              </template>
            </modal>
            <modal :ref="el => modal[i] = el">
              <template v-slot:trigger>
                <n-tooltip>
                  <template #trigger>
                    <button v-show="editable" class="hapus with-icon clear" id="delete"
                            @click="() => {currentModalI = i}">
                      <TrashCanOutline style="font-size: 24px"/>
                    </button>
                  </template>
                  Remove
                </n-tooltip>
              </template>
              <template v-slot:content>
                Are you sure you want to remove this schedule?
                <div class="h">
                  <n-button
                      type="default"
                      @click="batal(i)">
                    Cancel
                  </n-button>
                  <n-button
                      class="hapus accent"
                      id="confirm"
                      type="primary"
                      @click="hapus(activity.id)">
                    Remove
                  </n-button>
                </div>
              </template>
            </modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
// Penggunaan Schdeulelist ini didasarkan dari wireframe yang diberikan oleh artspedia
// Dengan asumsi tidak ada rentang waktu yang lebih dari 1 hari
import {
  NPopover, NDivider, FormInst, FormRules,
  NForm, NFormItem, NButton, NTooltip,
  NDatePicker, NSelect, FormItemRule
} from "naive-ui"
import {PencilOutline, TrashCanOutline, InformationOutline, DotsVertical} from "mdue";
import {ValidateError} from "async-validator";
import Modal from "@/components/Modal.vue";
import {QuillEditor} from "@vueup/vue-quill";
import {Schedule, ScheduleDto} from "@/types/Schedule";
import {getCurrentInstance, ref} from "vue";
import axios, {AxiosError} from "axios";
import {useRouter} from "vue-router";
import store from "@/store";

const router = useRouter();

const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message

const form = ref<FormInst[]>([])
const model = ref<Schedule>({} as Schedule)
model.value.description = ''
const modal = ref([])
const currentModal = ref(0)

const props = defineProps<{
  schedules?: any
  editable?: boolean
}>()
const typeGroup = props.schedules!.reduce((typeGroup: { [x: string]: any[] }, activity: { type: string; }) => {
  if (!typeGroup[activity.type]) {
    typeGroup[activity.type] = [];
  }
  typeGroup[activity.type].push(activity);
  return typeGroup;
}, {});

const currentModalI = ref(0)

const scheduleGroups = Object.keys(typeGroup).map((type) => {
  return {
    type,
    activity: typeGroup[type]
  }
})
function parseDateTime(date: Date) {
  // const months = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember']
  const months = ['JAN', 'FEB', 'MAR', 'APR', 'MEI', 'JUN', 'JUL', 'AGU', 'SEP', 'OKT', 'NOV', 'DES']
  let day = date.getDate().toString()
  if (day.length == 1) {
    day = "0"+day
  }
  return `${day} ${months[date.getMonth()]} ${date.getFullYear()} (${date.toTimeString().substring(0, 5)})`
}

const rules: FormRules = {
  start_time: [{
    required: true,
    type: 'number',
    message: "Start Time is required",
    trigger: ["change"]
  }],
  end_time: [{
    required: true,
    type: 'number',
    validator (rule:FormItemRule, value:Date) {
      if(!value) {
        return new Error('End Time is required')
      } else if(value < model.value.start_time) {
        return new Error('End Time must not be earlier than Start Time')
      }
    },
    trigger: ["change"]
  }],
}

function prepareEditData(activity: any) {
  activity.start_time = new Date(activity.start_time).getTime()
  activity.end_time = new Date(activity.end_time).getTime()
  model.value = activity
}

async function handleSubmit(errors: ValidateError[][] | undefined) {
  if (!errors) {
    let payload = new ScheduleDto(model.value);
    axios.patch("/api/schedule/" + model.value.id.toString(), payload)
        .then(_val => {
          message.success("Schedule is successfully updated!", {showIcon: true, closable: true});
          (modal.value as any)[currentModal.value].showModal = false;
          router.push("/schedule");
        })
        .catch((_err: AxiosError) => {
          message.error("A problem is occurred when updating schedule", {showIcon: true, closable: true});
        })
  }
}

function handleSubmitButtonClick(e: MouseEvent, i: number) {
  e.preventDefault();
  form.value![i].validate(handleSubmit).catch(_error => {
    message.error("Form is invalid", {showIcon: true, closable: true})
  })
}



function hapus(id: number) {
  axios.delete(`/api/schedule/${id}`,
      {headers: {Authorization: `Bearer ${store.state.token}`}}
  )
      .then(response => {
        message.success("Schedule is successfully removed!", {showIcon: true, closable: true})
        // getPortfolio(currentPage.value);
        app!.emit('schedule-delete');
        (modal.value as any)[currentModalI.value].showModal = false;
      })
      .catch((error) => {
        message.error("A problem is occurred when removing schedule. Please try again", {
          showIcon: true,
          closable: true
        })
        console.log(error)
      })
}

function batal(i: number) {
  // console.log(test);
  (modal.value as any)[i].showModal = false
}

</script>

<style lang="sass" scoped>
.n-divider
  margin-top: 10px

.schedule-card
  border: 1px solid $clr-light-gray
  @include pad(10px)
  flex-grow: 1

.monospace
  font-family: 'Azeret Mono', monospace
  font-weight: bold
</style>