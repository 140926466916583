export interface ChoicesDto {
    value: string
    display_name: string
}

export interface CategoryModel {
    name: string
}

export interface CityModel {
    name: string
}

export interface RegistrasiModel {
    email: string | null
    password: string | null
    confirmPassword: string | null
    full_name: string | null
    aliasname: string | null
    description: string | null
    category: string | null
    city: Array<string>
    cities: Array<string>
    categories: Array<string>
    portfolio_url_reg: string | null
}

export class RegistrasiDto {
    email: string | null
    password: string | null
    confirmPassword: string | null
    full_name: string | null
    aliasname: string | null
    description: string | null
    category: string | null
    city: string | null
    cities: Array<string>
    categories: Array<string | null>
    portfolio_url_reg: string | null

    constructor(model: RegistrasiModel) {
        this.email = model.email
        this.password = model.password
        this.confirmPassword = model.confirmPassword
        this.full_name = model.full_name || ''
        this.aliasname = model.aliasname || ''
        this.description = model.description || ''
        this.category = model.category
        this.city = model.city.join(', ')
        this.cities = model.cities
        this.categories = model.categories
        this.portfolio_url_reg = model.portfolio_url_reg
    }
}