<template>
    <n-calendar 
        class="calendar" 
        #="{ year, month, date }"
        @update:value="onSlotSelect"
        :default-value="new Date().getTime()"
        v-calendar-slot-click
        ref="calendarRef"
    >
        <div>
            <n-popover
                :show="showCalendarPop(year, month, date)"  
                :placement="'left'"
                :arrow-point-to-center="true"
                trigger="manual" 
                style="padding: 0"
                v-if="slotItems[`${year}-${month}-${date}`]"
            >
                <template #trigger>
                    <CalendarSlot 
                        :slotItems="slotItems[`${year}-${month}-${date}`]"
                    />
                </template>
                <CalendarPop 
                    :slotItems="slotItems[`${year}-${month}-${date}`]"
                    :date="date"
                />
            </n-popover>
            
        </div>
        
    </n-calendar>
</template>

<script setup lang="ts">
import { computed, defineComponent, Directive, onMounted, Ref, ref } from 'vue'
import { NCalendar, NPopover } from 'naive-ui'
import { CalendarSlots } from "@/types/Calendar"
import CalendarPop from "@/components/CalenderPop.vue";
import CalendarSlot from "@/components/CalendarSlot.vue"
import { ScheduleModel } from '@/types/ScheduleModel';

const calendarRef = ref(null)
const props = defineProps<{
    schedules: ScheduleModel[]
}>()

function roundNextDay(date: Date) {
    let offsetMs = date.getTimezoneOffset() * 60 * 1000
    let roundDown = date.getTime() - ((date.getTime() - offsetMs) % (24 * 3600 * 1000))
    let nextDay = roundDown + (24 * 3600 * 1000)
    return new Date(nextDay)
}

const slotItems = computed<CalendarSlots>(() => {
    let schedules = props.schedules
    let _slotItems: CalendarSlots = {}
    schedules.forEach((e: any) => {
        let startTime = new Date(e.start_time).getTime()
        let endTime = new Date(e.end_time).getTime()
        let description = e.description
        while(startTime < endTime) {
            let startDate = new Date(startTime)
            let nextDate = new Date(roundNextDay(new Date(startTime)).getTime() - 1000)
            let endDate = new Date(Math.min(nextDate.getTime(), endTime))
            let key = `${startDate.getFullYear()}-${startDate.getMonth()+1}-${startDate.getDate()}`
            let items = _slotItems[key] || []
            items.push({
                start: startDate,
                end: endDate,
                description: description,
                type: e.type
            })
            _slotItems[key] = items
            startTime = endDate.getTime() + 1000
        }
    });
    return _slotItems
})


let slotTime = ref([0,0,0])
function onSlotSelect(timestamp: number, info: { year: number, month: number, date: number }) {
    if (timestamp == null)
        return
    slotTime.value = [info.year, info.month, info.date]
}
function showCalendarPop(year: number, month: number, date: number) {
    return slotTime.value.join() == [year, month, date].join()
}
const vCalendarSlotClick: Directive = {
    mounted: (element) => {
        element.clickEvent = (event: PointerEvent) => {
            let maybePopover = (event.target as any).closest('.n-popover')
            let maybeCalendarSlot = (event.target as any).closest('.n-calendar-cell')
            if (!maybePopover && !maybeCalendarSlot) {
                slotTime.value = [0,0,0];
                (calendarRef.value! as any).doUpdateValue(null, {})
            }
        }
        document.body.addEventListener('click', element.clickEvent)
    },
    unmounted: (element) => {
        document.body.removeEventListener('click', element.clickEvent)
    }
}
</script>


<style lang="sass" scoped>
:deep(.n-calendar-cell)
    min-height: 100px
    padding-left: 4px
    padding-right: 4px
:deep(.n-calendar-cell__bar)
    height: 0!important
.calendar
    min-height: 35em    
</style>