import { createLocale, enUS } from 'naive-ui'

export const idID = createLocale(
    {
        name: 'id-ID',
        global: {
            undo: 'Undo',
            redo: 'Redo',
            confirm: 'Confirm'
        },
        Popconfirm: {
            positiveText: 'Confirm',
            negativeText: 'Cancel'
        },
        Cascader: {
            placeholder: 'Select option',
            loading: 'Loading',
            loadingRequiredMessage: (label) => `Harap muat semua turunan ${label} sebelum memeriksanya.`
        },
        Time: {
            dateFormat: 'yyyy-MM-dd',
            dateTimeFormat: 'yyyy-MM-dd HH:mm:ss'
        },
        DatePicker: {
            yearFormat: 'yyyy',
            monthFormat: 'MMM',
            dayFormat: 'eeeeee',
            yearTypeFormat: 'yyyy',
            monthTypeFormat: 'yyyy-MM',
            dateFormat: 'yyyy-MM-dd',
            dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
            quarterFormat: 'yyyy-qqq',
            clear: 'Clear',
            now: 'Now',
            confirm: 'Confirm',
            selectTime: 'Select Time',
            selectDate: 'Select Date',
            datePlaceholder: 'Select Date',
            datetimePlaceholder: 'Select Date and Time',
            monthPlaceholder: 'Select Month',
            yearPlaceholder: 'Select Year',
            quarterPlaceholder: 'Select Quarter',
            startDatePlaceholder: 'Start Date',
            endDatePlaceholder: 'End Date',
            startDatetimePlaceholder: 'Start Date and Time',
            endDatetimePlaceholder: 'End Date and Time',
            monthBeforeYear: true,
            firstDayOfWeek: 6,
            today: 'Today'
        },
        DataTable: {
            checkTableAll: 'Select all in table ',
            uncheckTableAll: 'Cancel all selections di tabel ',
            confirm: 'Confirm',
            clear: 'Clear'
        },
        Transfer: {
            sourceTitle: 'Source',
            targetTitle: 'Target'
        },
        Empty: {
            description: 'Empty Data'
        },
        Select: {
            placeholder: 'Please select'
        },
        TimePicker: {
            placeholder: 'Select Time',
            positiveText: 'OK',
            negativeText: 'Cancel',
            now: 'Now'
        },
        Pagination: {
            goto: 'Go to',
            selectionSuffix: 'page'
        },
        DynamicTags: {
            add: 'Add'
        },
        Log: {
            loading: 'Loading'
        },
        Input: {
            placeholder: 'Enter Input'
        },
        InputNumber: {
            placeholder: 'Enter Input'
        },
        DynamicInput: {
            create: 'Create'
        },
        ThemeEditor: {
            title: 'Theme Editor',
            clearAllVars: 'Clear All Variables',
            clearSearch: 'Clear Search',
            filterCompName: 'Filter Component Name',
            filterVarName: 'Filter Variable Name',
            import: 'Import',
            export: 'Export',
            restore: 'Restore'
        },
        // TODO: translation
        Image: {
            tipPrevious: 'Previous Image (←)',
            tipNext: 'Next Image (→)',
            tipCounterclockwise: 'Counter Clockwise ',
            tipClockwise: 'Clockwise',
            tipZoomOut: 'Zoom Out',
            tipZoomIn: 'Zoom In',
            tipClose: 'Close (Esc)'
        }
    },
    enUS
)

export const naiveThemeOverride = {
  "common": {
    "primaryColor": "#FF8E54FF",
    "primaryColorHover": "#F9AC71FF",
    "primaryColorPressed": "#F1834BFF",
    "primaryColorSuppl": "#F2A781FF",
  },
  "Card": {
    "boxShadow": "rgba(0, 0, 0, 0.12) 0px 4px 4px 0px",
    "titleFontSizeSmall": "14px",
    "titleFontSizeMedium": "16px",
    "titleFontSizeLarge": "16px",
    "titleFontSizeHuge": "16px",
    "borderRadius": "6px",
    "color": "#fff"
  },
  "Button": {
    "color": "#0000"
  },
  "Input": {
    "borderRadius": "4px",
    // "border": "2px solid #EE5F16",
    "borderDisabled": "2px solid #EE5F1666"
  },
  "Select": {
    "peers": {
      "InternalSelection": {
        // "border": "2px solid #EE5F16"
      }
    }
  },
  "DynamicTags": {
    "peers": {
      "Button": {
        "border": "2px solid #EE5F16"
      },
      "Tag": {
        "border": "2px solid #EE5F1666"
      }
    }
  }
}