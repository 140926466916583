export interface Schedule {
    id: number,
    user: string,
    start_time: Date,
    end_time: Date,
    type: string
    description: string,
}

export interface ScheduleTypeDto {
    name: string
}

export class ScheduleDto {
    id: number | null
    user: string | null
    start_time: string | null
    end_time: string | null
    type: string | null
    description: string | null

    constructor(model: Schedule) {
        this.id = model.id
        this.user = model.user
        this.start_time = new Date(model.start_time).toISOString()
        this.end_time = new Date(model.end_time).toISOString()
        this.type = model.type
        this.description = model.description
    }
}