<template>
  <n-config-provider 
    :locale="locale" 
    :theme-overrides="naiveThemeOverride" 
    :breakpoints="{s:900}"
  >
    <n-message-provider>
      <div id="main-content">
        <MessageWrapper />
        <NavBar ref="navbar" style="z-index: 1000"/>
        <div id="wrapper" :class="{ mobile: store.isMobile}">
            <router-view></router-view>
        </div>
      </div>
    </n-message-provider>
  </n-config-provider>
</template>

<script lang="ts">
import axios from 'axios';
import { Options, Vue } from 'vue-class-component';
import { NMessageProvider, NConfigProvider, GlobalThemeOverrides  } from 'naive-ui';
import NavBar from '@/components/NavBar.vue';
import MessageWrapper from '@/components/MessageWrapper.vue'
import '@/assets/sass/_base.sass';
import store from "@/store/index";
import { idID, naiveThemeOverride } from '@/naive-config'

@Options({
  components: {
    NavBar, NMessageProvider, NConfigProvider, MessageWrapper
  },
  data() {
    return {
      store,
      naiveThemeOverride,
      locale: idID
    }
  },
  beforeCreate() {
    this.$store.commit('initializeStore')
    const token = this.$store.state.token
    axios.defaults.baseURL = process.env.VUE_APP_API_URL
    axios.defaults.headers.common['Accept-Language'] = 'id-ID'
    if (token) {
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    } else {
      axios.defaults.headers.common['Authorization'] = ''
    }
  }
})
export default class App extends Vue {}
</script>
