<template>
  <div class="container">
    <div class="card">
      <profile-row v-for="(artist, idx) in artists.results" :key="idx" :approval="true" :idx="artist.user.email"
                   :artist="artist"/>
      <pagination
          :maxVisibleButtons="5"
          :totalPages="Math.ceil(artists.count/10) || 1"
          :perPage="10"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
      />
    </div>
  </div>
</template>
<script lang="ts">
import * as Vue from "vue";
import store from "@/store";
import ProfileRow from "@/components/ProfileRow.vue";
import Pagination from "@/components/Pagination.vue";
import {ProfileResponse} from "@/types/ProfileResponse";
import axios from "axios";
import {NSpin} from "naive-ui";

export default Vue.defineComponent({
  components: {
    ProfileRow, NSpin, Pagination
  },
  setup() {
    const artistListRef = Vue.ref<ProfileResponse | null>({
      count: 1,
      next: null,
      previous: null,
      results: []
    })
    const currentPage = Vue.ref(1)
    const wrapper = document.getElementById('wrapper')

    getData(1)

    async function getData(page: any) {
      await axios.get(`/api/profile/all?page=${page}`,
          {headers: {Authorization: `Bearer ${store.state.token}`}}
      ).then(
          response => {
            artistListRef.value = response.data
          }).catch(
          error => console.log(error)
      )
      wrapper!.scroll({top: 0, behavior: 'smooth'})
    }

    function onPageChange(page: any) {
      currentPage.value = page;
      getData(page);
    }

    return {
      artists: artistListRef,
      getData,
      onPageChange,
      currentPage,
      store,
    };
  }
});
</script>