<template>
    <div class="container">
        <n-card size="small">
            <template #header>
                <n-space id="form-title" vertical align="center" size="small">
                    <div id="brand" />
                    <h3>Register</h3>
                </n-space>
            </template>
            <n-form ref="form" :model="model" :rules="rules">
                <n-form-item id="emailForm" path="email" first label="Email">
                    <n-input 
                        id="email"
                        v-model:value="model.email" 
                        @keydown.enter.prevent 
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="passwordForm" path="password" first label="Password">
                    <n-input
                        id="password"
                        v-model:value="model.password"
                        type="password"
                        show-password-on="mousedown"
                        @keydown.enter.prevent
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="confirmPasswordForm" ref="passwordFormItem" path="confirmPassword" first label="Confirm Password">
                    <n-input
                        id="confirmPassword"
                        v-model:value="model.confirmPassword"
                        :disabled="!model.password"
                        type="password"
                        show-password-on="mousedown"
                        @keydown.enter.prevent
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="fullNameForm" path="full_name" first label="Full Name">
                    <n-input 
                        id="fullName"
                        v-model:value="model.full_name" 
                        @keydown.enter.prevent 
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="artist-aliasname-form" path="aliasname" label="Alias Name">
                  <n-input
                      id="aliasname" 
                      v-model:value="model.aliasname"
                      @keydown.enter.prevent size="large"
                      placeholder="Enter your alias name. This will be used for the URL to your profile"
                  />
                </n-form-item>
                <n-form-item id="descriptionForm" path="description" first label="Description">
                    <n-input 
                        id="description"
                        v-model:value="model.description" 
                        @keydown.enter.prevent 
                        size="large"
                        type="textarea"
                    />
                </n-form-item>
                <n-form-item id="categoryForm" path="categories" label="Category Artist">
                    <n-select 
                        id="categories"
                        v-model:value="model.categories"
                        :options='categoryOptions'
                        filterable
                        clearable
                        multiple
                        remote
                        @keydown.enter.prevent 
                        @search='categorySearch'
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="portfolioUrlRegForm" path="portfolio_url_reg" first label="Portfolio URL">
                    <n-input 
                        id="portfolio_url_reg"
                        v-model:value="model.portfolio_url_reg" 
                        @keydown.enter.prevent 
                        size="small"
                        type="textarea"
                        placeholder="Enter the URL or link to your online portfolio, for example Instagram, Youtube or something else"
                    />
                </n-form-item>
                <n-form-item id="cityForm" path="cities" label="City">
                  <n-select 
                    id="cities"
                    v-model:value="model.cities" 
                    multiple clearable filterable remote
                    size="large"
                    @keydown.enter.prevent
                    :options='cityOptions'
                    @search='citySearch'
                    />
                </n-form-item>

                <n-button
                    id="submitBtn"
                    :disabled="model.age === null"
                    :loading="registerLoading" 
                    type="primary"
                    attr-type="submit"
                    @click="handleValidateButtonClick"
                    size="large"
                >
                    <strong>Register</strong>
                </n-button>
            </n-form>
            <n-space vertical align="center">
                <br>
                <strong>Already have an account?</strong>

                <router-link class="router-link" to="/login">
                    <strong>Login</strong>
                </router-link>
                <br>
            </n-space>
        </n-card>
    </div>
</template>

<style lang="sass" scoped>
button
    background-color: var(--n-color)
h3
    margin: 0
#form-title
    padding: 30px
.container
    @include row
    justify-content: center
    background-color: $clr-light
.n-card
    max-width: 500px
.n-button
    width: 100%
.router-link
    color: $clr-brand
.router-link:hover
    color: $clr-dark
#brand
    @include square(_(64px))
    background-image: url('@/assets/img/brand.png')
    background-size: contain
    background-repeat: no-repeat
</style>


<script setup lang="ts">
import { ref, watch, nextTick, computed, inject, Ref, getCurrentInstance } from 'vue'
import { useRouter } from 'vue-router'
import { 
    FormInst, FormItemInst, FormItemRule, FormValidationError,
    FormRules, NCard, NForm, NInput, NFormItem, NSelect, NRow, NCol, NButton,
    NDynamicTags, SelectOption, NSpace, NAutoComplete, AutoCompleteInst
} from 'naive-ui'
import axios from 'axios'
import { ChoicesDto, RegistrasiModel, RegistrasiDto, CategoryModel, CityModel } from '@/types/Registrasi'
import { MessageApiInjection } from 'naive-ui/lib/message/src/MessageProvider'
import { ValidateError } from 'async-validator'
import store from '@/store'

const parameters = ["email", "password", "full_name", "aliasname", "description", "category", "city", "cities", "categories"]
const router = useRouter()
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message 
const model = ref<RegistrasiModel>({
    email: null,
    password: null,
    confirmPassword: null,
    category: null,
    full_name: null,
    aliasname: null,
    description: null,
    city: [],
    cities: [],
    categories: [],
    portfolio_url_reg: null
})

const categoryLoading = ref(true)
var categoryData: SelectOption[] = []   // Store categories data from backend
var cityData: SelectOption[] = []   // Store categories data from backend
const categoryOptions = ref<SelectOption[]>([])
const cityOptions = ref<SelectOption[]>([])

function categorySearch(query: string) {
    if (!query.length) {
        categoryOptions.value = categoryData
    } else {
        categoryLoading.value = true
        var res: SelectOption[] = categoryData.filter((item) => ~item.value.toString().toLowerCase().indexOf(query.toLowerCase()))
        categoryOptions.value = res
        categoryLoading.value = false
    }
}
function citySearch(query: string) {
    if (!query.length) {
        cityOptions.value = cityData
    } else {
        var res: SelectOption[] = cityData.filter((item) => ~item.value.toString().toLowerCase().indexOf(query.toLowerCase()))
        cityOptions.value = res
    }
}
function getRegisterOptions() {
    return axios.options('/api/auth/register/')
}
function getCategories() {
    return axios.get('/api/profile/category')
}
function getCities() {
    return axios.get('/api/profile/city')
}
axios.all([getCategories(), getCities()])
.then((results) => {
    let categoriesTemp = results[0].data
    let cityTemp = results[1].data

    cityData = cityTemp.map((el: CityModel) => {
        return {value: el.name, label: el.name}
    })
    cityOptions.value = cityTemp.map((el: CityModel) => {
        return {value: el.name, label: el.name}
    })

    categoryData = categoriesTemp.map((el: CategoryModel) => {
        return {value: el.name, label: el.name}
    })
    categoryOptions.value = categoriesTemp.map((el: CategoryModel) => {
        return {value: el.name, label: el.name}
    })
    categoryLoading.value = false    
})
.catch((error) => {
    message.error("Cannot load registration options", {showIcon: true, closable: true})
});

const form = ref<FormInst | null>(null)
const passwordFormItem = ref<FormItemInst | null>(null)
const rules: FormRules = {
    email: [
        {
            required: true,
            message: 'Email is required',
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /\b[\w.\-+]+@[\w.-]+.\w{2,4}\b/.test(value)
            },
            message: 'Email is invalid',
            trigger: ['change']
        }
    ],
    password: [
        {
            required: true,
            message: "Password is required",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return value.length >= 8;
            },
            message: "Password must be at least 8 characters",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /\d/.test(value)
            },
            message: "Password must have at least a number",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /[a-zA-Z]/.test(value)
            },
            message: "Password must have at least an alphabet character",
            trigger: ['change']
        },
    ],
    confirmPassword: [
        {
            required: true,
            message: 'Confirm password is required',
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string): boolean {
                return value === model.value.password
            },
            message: 'Passwords are different',
            trigger: ['change', 'password-input']
        }
    ],
    aliasname: [
        {
            required: true,
            message: 'Alias name is required',
            trigger: ['change']
        }
    ],
    cities: [
        {
            required: true,
            validator (rule: FormItemRule, value: string): boolean {
                return value.length > 0
            },
            message: 'At least 1 city is required',
            trigger: ['change']
        }
    ],
    categories: [
        {
            required: true,
            validator (rule: FormItemRule, value: string): boolean {
                return value.length > 0
            },
            message: 'At least 1 artist category is required',
            trigger: ['change']
        }
    ],
    portfolio_url_reg: [{
            required: true,
            message: 'URL/link to existing portfolio is required',
            trigger: ['change']
        }
    ],
}
function login() {
    axios.post('/api/auth/token/', {
        email: model.value.email,
        password: model.value.password
    })
    .then((response) => {
        registerLoading.value = false
        const token = response.data.access;
        const refresh = response.data.refresh;
        store.commit("setToken", token);
        store.commit("setRefresh", refresh);
        store.commit("setIdentity", token);

        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        router.push({name: "verify-email", params: {
            fromRegister: true
        }} as any)
        
    })
    .catch((error) => {
        registerLoading.value = false
        message.error("A problem is occurred when logging in", {showIcon: true, closable: true})
        router.push('/login')
    })
    model.value = {
        email: null,
        password: null,
        confirmPassword: null,
        category: null,
        full_name: null,
        aliasname: null,
        description: null,
        city: [],
        cities: [],
        categories: [],
        portfolio_url_reg: null
    }
}
const registerLoading = ref(false)
function validateForm(errors: ValidateError[][] | undefined) {
    if (!errors) {
        let payload = new RegistrasiDto(model.value)
        console.log(payload)
        registerLoading.value = true

        axios.post('/api/auth/register/', payload)
        .then((response) => {
            login()
        })
        .catch((error) => {
            registerLoading.value = false
            var keys: string[] = []
            console.log(error)
            if (error.response.data != null) {
                keys = Object.getOwnPropertyNames(error.response.data)
            }
            var variableErr: string[] = []
            keys.forEach((v) => {
                if (parameters.includes(v)) {
                    error.response.data[v].forEach((element: string) => {
                        variableErr.push(element)
                    });
                }
            })
            if (variableErr.length) {
                message.error(variableErr[0], {showIcon: true, closable: true})
            } else {
                message.error("A problem is occurred during registration", {showIcon: true, closable: true})
                console.error(error)
            }
        })
    } 
}
function handleValidateButtonClick (e: MouseEvent) {
    e.preventDefault()
    form.value!.validate(validateForm).catch(error => {
        message.error('Registration form is invalid', {showIcon: true, closable: true})
    })
}

const cityAutoComplete = ref<AutoCompleteInst | null>(null)
const cityInput = ref('')

</script>