<template>
  <n-spin :show="load">
    <div class="profile-row" :class="{ mobile: store.state.isMobile }">
      <div class="h">
        <div
            class="photo"
            :style="{'background-image': 'url('+ (artist.photo_url || '/') +')', 'background-color': '#FBEEE6'}"
        />

        <div class="v">
          <modal>
            <template v-slot:trigger>
              <div class="artist-full-name">{{ artist.user.full_name }}</div>
            </template>
            <template v-slot:content>
              <profile :artist="artist" :edittable="false"/>
            </template>
          </modal>
          <div id="small">{{ artist.user.email }}</div>
          <div class="status" ref="status" :class="{ green: artist.status }">{{
              artist.status ? 'ACTIVE' : 'INACTIVE'
            }}
          </div>
        </div>
      </div>
      <n-tooltip>
        <template #trigger>
          <button class="green with-icon" @click="terima" v-show="approval && !artist.status">
            <Check style="font-size: 24px"/>
          </button>
        </template>
        Approve
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <button class="accent with-icon" @click="tolak" v-show="approval && artist.status">
            <WindowClose style="font-size: 24px"/>
          </button>
        </template>
        Decline
      </n-tooltip>
      <n-tooltip>
        <template #trigger>
          <button
              v-show="!approval"
              class="brand with-icon"
              @click="$router.push({ name: 'detailprofil', params: { id: idx } })"

          >
            <ImageText style="font-size: 24px"/>
          </button>
        </template>
        Detail
      </n-tooltip>
    </div>
  </n-spin>
</template>
<script lang="ts">
import * as Vue from "vue";
import store from "@/store";
import axios from "axios";
import Modal from "@/components/Modal.vue";
import Profile from "@/components/Profile.vue";
import {Check, ImageText, WindowClose} from 'mdue';
import {NSpin, NTooltip} from "naive-ui";

export default Vue.defineComponent({
      components: {
        Modal, Profile, Check, WindowClose, ImageText, NTooltip, NSpin
      },
      props: ["artist", "approval", "idx"],
      setup(props) {
        const { artist: artist } = Vue.toRefs(props)
        const load = Vue.ref(false)
        const status = Vue.ref(null)
        return {
          artist,
          load,
          status,
          terima() {
            load.value = true;
            let user = props.idx
            let span = status.value as unknown as HTMLElement
            axios
                .put(
                    '/api/profile/status',
                    {
                      user: user,
                      status: true
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${store.state.token}`
                      }
                    }
                )
                .then(
                    response => {
                      // setTimeout(() => {
                        if (response.data.status as boolean) {
                          span.classList.add("green")
                          span.innerHTML = "ACTIVE"
                          artist.value.status = true
                        }
                        load.value = false
                      // }, 10000)
                    }
                ).catch(error => {
              console.log(error)
              load.value = false
            })
          },
          tolak() {
            load.value = true;
            let user = props.idx
            let span = status.value as unknown as HTMLElement
            axios
                .put(
                    '/api/profile/status',
                    {
                      user: user,
                      status: false
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${store.state.token}`
                      }
                    }
                )
                .then(
                    response => {
                      // setTimeout(() => {
                        if (!response.data.status as boolean) {
                          span.classList.remove("green")
                          span.innerHTML = "INACTIVE"
                          artist.value.status = false
                        }
                        load.value = false
                      // }, 1000)
                    }
                ).catch(error => {
              console.log(error)
              load.value = false
            })
          }
        }
      },
      data() {
        return {
          store,
        };
      },
    }
);
</script>

<style lang="sass" scoped>
.photo
  @include square(_(100px))

.profile-row
  background-color: $clr-white
  border: 1px solid $clr-light-gray
  @include pad(_(15px))
  @include row

  &.mobile

.h
  @include truncate
  flex-grow: 1

.v
  @include col(_(2px))
  @include fit
  @include truncate

  div
    @include truncate

button
  flex-shrink: 0

  &.brand
    display: flex
    width: initial

#small
  color: $clr-dark

.artist-full-name
  font-size: _(24px)
  font-weight: bold

  &:hover
    color: $clr-medium

  &:active
    color: $clr-dark
</style>