import { createStore } from 'vuex'
import jwtDecode from "jwt-decode";

var isMobile = /Mobi/i.test(window.navigator.userAgent)

export default createStore({
    state: {
        isAuthenticated: false,
        token: '',
        refresh: '',
        isMobile: isMobile,
        title: null,
        isAdmin: false,
        email: '',
        userId: -1,
        isEmailVerified: false,
        photoUrl: ''
    },
    mutations: {
        initializeStore(state) {
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token')
                state.refresh = localStorage.getItem('refresh')
                state.isAuthenticated = true

                var data = jwtDecode(state.token)
                state.isAdmin = data.is_admin
                state.email = data.email
                state.userId = data.userId
                state.isEmailVerified = data.is_email_verified
            } else {
                state.token = ''
                state.isAuthenticated = false
            }
        },
        setToken(state, token) {
            state.token = token
            state.isAuthenticated = true
            localStorage.setItem("token", token);
        },
        setRefresh(state, refresh) {
            state.refresh = refresh
            state.isAuthenticated = true
            localStorage.setItem("refresh", refresh);
        },
        setIdentity(state, token) {
            var data = jwtDecode(token)
            state.isAdmin = data.is_admin
            state.email = data.email
            state.userId = data.userId
            state.isEmailVerified = data.is_email_verified
        },
        setPhotoUrl(state, url) {
            state.photoUrl = url;
        },
        removeToken(state) {
            state.token = ''
            state.isAuthenticated = false
            localStorage.removeItem('token')

            state.isAdmin = false
            state.email = ''
            state.userId = -1
            state.isEmailVerified = false
        },
        removeRefresh(state) {
            state.refresh = ''
            state.isAuthenticated = false
            localStorage.removeItem('refresh')
        }
    },
    actions: {},
    modules: {}
})