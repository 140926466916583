export interface LoginModel {
    email: string | null
    password: string | null
}

export class LoginDto {
    email: string | null
    password: string | null

    constructor(model: LoginModel) {
        this.email = model.email
        this.password = model.password
    }
}