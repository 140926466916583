<template>
    <div class="container">
        <n-card size="small">
            <template #header>
                <n-space id="form-title" vertical align="center" size="small">
                    <h3>Forget Password</h3>
                </n-space>
            </template>
            <n-form v-if="reset" ref="resetForm" :model="resetModel" :rules="resetRules">
                <n-form-item id="passwordForm" path="password" first label="Password">
                    <n-input
                        id="password"
                        v-model:value="resetModel.password"
                        type="password"
                        show-password-on="mousedown"
                        @keydown.enter.prevent
                        size="large"
                    />
                </n-form-item>
                <n-form-item id="confirmPasswordForm" ref="passwordFormItem" path="confirmPassword" first label="Confirm Password">
                    <n-input
                        id="confirmPassword"
                        v-model:value="resetModel.confirmPassword"
                        :disabled="!resetModel.password"
                        type="password"
                        show-password-on="mousedown"
                        size="large"
                    />
                </n-form-item>
                <n-button
                    id="submitBtn"
                    :loading="resetLoading" 
                    type="primary"
                    attr-type="submit"
                    @click="resetHandleValidate"
                    size="large"
                >
                    <strong>Change Password</strong>
                </n-button>
            </n-form>
            <n-form v-if="sendReset" ref="sendResetForm" :model="sendResetModel" :rules="sendResetRules">
                <n-form-item id="emailForm" path="email" first label="Email">
                    <n-input 
                        id="email"
                        v-model:value="sendResetModel.email" 
                        size="large"
                    />
                </n-form-item>
                <n-button
                    id="submitBtn"
                    :loading="sendResetLoading" 
                    type="primary"
                    attr-type="submit"
                    @click="sendResetHandleValidate"
                    size="large"
                >
                    <strong>Send Link</strong>
                </n-button>
            </n-form>
        </n-card>
    </div>
</template>

<style lang="sass" scoped>
.n-card
    max-width: 500px
    width: 100%
.container
    justify-content: center
    background-color: $clr-light
.router-link
    color: $clr-brand
.router-link:hover
    color: $clr-dark
#brand
    @include square(_(64px))
    background-image: url('@/assets/img/brand.png')
    background-size: contain
    background-repeat: no-repeat
</style>

<script setup lang="ts">
import { ref, getCurrentInstance, watch, Ref } from 'vue'
import { useRouter } from 'vue-router'
import { 
    NCard, NSpace, NButton, NForm, NFormItem, FormRules, FormItemRule, FormInst, NInput
} from 'naive-ui'
import { ValidateError } from 'async-validator'
import axios from 'axios'
import store from '@/store'
import { ResetPasswordModel, SendResetPasswordModel } from '@/types/ForgetPassword'
import { FormValidateCallback } from 'naive-ui/lib/form/src/interface'
const router = useRouter()
const app = getCurrentInstance()
const message = app!.appContext.config.globalProperties.message
const props = defineProps<{
    token?: string
}>()

const reset = ref(false)
const sendReset = ref(!props.token)

if (props.token) {
    axios.get(`/api/auth/forget-password/check/${props.token}/`)
    .then((response) => {
        reset.value = true
    })
    .catch((error) => {
        let messageContent = 'A problem is occurred when generating reset password form'
        if ([404, 400].includes(error.response.status)) {
            messageContent = 'Reset password link is expired or invalid'
        }
        message.error(messageContent, {showIcon: true, closable: true})
        router.push('/logout')
    })
}

const resetLoading = ref(false)
const sendResetLoading = ref(false)

const resetForm = ref<FormInst | null>(null)
const sendResetForm = ref<FormInst | null>(null)
const resetModel = ref<ResetPasswordModel>({})
const sendResetModel = ref<SendResetPasswordModel>({})

const resetRules: FormRules = {    
    password: [
        {
            required: true,
            message: "Password is required",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return value.length >= 8;
            },
            message: "Password must be at least 8 characters",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /\d/.test(value)
            },
            message: "Password must have at least a number",
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /[a-zA-Z]/.test(value)
            },
            message: "Password must have at least an alphabet character",
            trigger: ['change']
        },
    ],
    confirmPassword: [
        {
            required: true,
            message: 'Confirm password is required',
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string): boolean {
                return value === resetModel.value.password
            },
            message: 'Passwords are different',
            trigger: ['change', 'password-input']
        }
    ],
}
const sendResetRules: FormRules = {
    email: [
        {
            required: true,
            message: 'Email is required',
            trigger: ['change']
        },
        {
            validator (rule: FormItemRule, value: string) {
                return /\b[\w.\-+]+@[\w.-]+.\w{2,4}\b/.test(value)
            },
            message: 'Email is invalid',
            trigger: ['change']
        }
    ],
}

function handleValidate(e: MouseEvent, form: Ref<FormInst | null>, fallbackFun: FormValidateCallback) {
    e.preventDefault()
    form.value!.validate(fallbackFun).catch(error => {
        message.error('Form is invalid', {showIcon: true, closable: true})
        resetLoading.value = false
    })
}

function resetHandleValidate(e: MouseEvent) {
    handleValidate(e, resetForm, resetValidateForm)
}
function sendResetHandleValidate(e: MouseEvent) {
    handleValidate(e, sendResetForm, sendResetValidateForm)
}
function resetValidateForm(errors: ValidateError[][] | undefined) {
    if (!errors) {
        resetLoading.value = true
        resetModel.value.token = props.token
        axios.post('/api/auth/forget-password/', resetModel.value)
            .then((response) => {
                message.success("Password is successfully reset! Please login", {showIcon: true, closable: true})
                router.push("/logout")
            })
            .catch((error) => {
                let messageContent = "A problem is occurred when resetting password"
                if ('message' in (error.response.data || {})) {
                    messageContent = error.response.data.message
                }
                message.error(messageContent, {showIcon: true, closable: true})
                resetLoading.value = false
            })
    } 
}

function sendResetValidateForm(errors: ValidateError[][] | undefined) {
    if (!errors) {
        sendResetLoading.value = true
        resetModel.value.token = props.token
        axios.get(`/api/auth/forget-password/${sendResetModel.value.email}/`)
            .then((response) => {
                message.success("Link reset password is successfully sent!", {showIcon: true, closable: true})
                router.push("/logout")
            })
            .catch((error) => {
                let messageContent = "A problem is occurred when sending reset password link"
                if (error.response.status == 404) {
                    messageContent = "No user found with this email address"
                }
                message.error(messageContent, {showIcon: true, closable: true})
                sendResetLoading.value = false
            })
    } 
}
</script>