<template>
  <div class="container">
    <div class="card">
      <div id="detail-navigation">
        <button
            @click="setContent('overview')"
            :class="{ pop: showContent('overview') }"
        >
          Overview
        </button>
        <button
            @click="setContent('portfolio')"
            :class="{ pop: showContent('portfolio') }"
        >
          Portfolio
        </button>
        <button
            @click="setContent('service')"
            :class="{ pop: showContent('service') }"
        >
          Service
        </button>
        <button
            @click="setContent('jadwal')"
            :class="{ pop: showContent('jadwal') }"
        >
          Schedule
        </button>
      </div>
      <div id="detail=content">
        <div
            class="detail-content"
            id="overview"
            v-show="showContent('overview')"
        >
          <Profile v-if="artist != null" :artist="artist" :editable="false"/>
        </div>
        <div
            class="detail-content"
            id="portfolio"
            v-show="showContent('portfolio')"
        >
          <portfolio-list :editable="false" :url="'/api/profile/portfolio/'+props.id"/>
        </div>
        <div
            class="detail-content"
            id="service"
            v-show="showContent('service')"
        >
          <service-list :editable="false" :url="'/api/profile/service/'+props.id"/>
        </div>
        <div class="detail-content" id="jadwal" v-show="showContent('jadwal')">
          <schedule :editable="false" :url="'/api/profile/schedule/'+props.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import * as Vue from "vue";
import store from '@/store'
import json from '@/mock.json'
import '@/assets/sass/profil.sass'

import Profile from "@/components/Profile.vue";
import PortfolioList from "@/components/PortfolioList.vue"


import {ref} from "@vue/runtime-core";
import axios from "axios";
import {ProfileResponse} from "@/types/ProfileResponse";
import ServiceList from "@/components/ServiceList.vue";
import Schedule from "@/components/Schedule.vue";

const props = defineProps<{
  id?: string
}>()

const content = ref("overview");

function setContent(contentId: string) {
  content.value = contentId
}
function showContent(id: string) {
  return content.value === id
}

const artist = ref(null)

getInfo()

const service = json.service[1]

async function getInfo() {
  await axios.get('/api/profile/info/'+props.id)
      .then(response => {
        artist.value = response.data.results[0]
      })
      .catch(error => console.log(error))
}


</script>

<style lang="sass" scoped>
#detail-navigation
  @include row(5px)

  button
    font-weight: 600
    font-size: _(24px)

    &:hover
      box-shadow: none

  .pop
    color: $clr-brand

.detail-content
  @include pad(_(15px))

  &#service
    @include col(20px)

  &#portfolio
    @include row(20px)
    flex-wrap: wrap

    @media (max-width: 900px)
      @include col

.portfolio-card
  transition: 0.2s
  background-color: $clr-white
  cursor: pointer
  @include card
  @include round(0)
  @include col
  height: _(500px)
  width: _(300px)

  &:hover
    @include shade(10px)
    transform: translateY(-10px) scale(1.05)

  &:active
    filter: brightness(0.9)

  @media (max-width: 900px)
    width: 100%
    height: _(300px)

.portfolio-card-content
  @include fit
  overflow: hidden
  flex-shrink: 1

.service-card
  @include card
  @include round(0)
  @include col

h1
  margin: 0
  font-size: _(28px)

p
  font-size: _(24px)
  @include truncate
  white-space: pre-wrap

h5
  margin: 0
  color: $clr-medium
</style>