<template>
  <div class="card-content">
    <div class="card-header">
      <div class="card-gap"></div>
      <div class="photo"
           :style="bgImgPlaceholder"></div>
      <p class="artist-name">{{ artist!.user.full_name }}</p>
      <div class="status" ref="status" :class="{ green: artist.status }">{{ artist.status ? 'ACTIVE' : 'INACTIVE' }}</div>
    </div>
    <div class="sep" v-if="!store.state.isMobile"></div>
    <div class="card-body">
      <div class="card-body-content">
        <div class="card-body-text">
          <div class="artist-info">
            <span>Full Name</span>
            <p>{{ artist.user.full_name }}</p>
          </div>
          <div class="artist-info">
            <span>Alias Name</span>
            <p>{{ artist.aliasname }}</p>
          </div>
          <div class="artist-info">
            <span>Category</span>
            <div class="artist-tag">
              <div class="profile-tag" v-for="category in artist.categories" :key="category">{{ category }}</div>
            </div>
          </div>
          <div class="artist-info">
            <span>City</span>
            <div class="artist-tag">
              <div class="profile-tag" v-for="city in artist.cities" :key="city">{{ city }}</div>
            </div>
          </div>
          <div class="artist-info">
            <span>Description</span>
            <p>{{ artist.user.description }}</p>
          </div>
          <div class="artist-info">
            <span>Address</span>
            <p>{{ artist.address }}</p>
          </div>
          <div class="artist-info">
            <span>Phone Number</span>
            <p>{{ artist.phone_number }}</p>
          </div>
          <div class="artist-info">
            <span>Website</span>
            <p>{{ artist.website }}</p>
          </div>
          <div class="artist-info" v-if="!artist.status">
            <span>Portfolio URL for Registration</span>
            <p>{{ artist.portfolio_url_reg }}</p>
          </div>
        </div>
        <button v-show="editable" @click="editPage" class="brand">Edit Profile</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import '@/assets/sass/profil.sass'
import store from "@/store";
import {useRouter} from "vue-router";
import { ArtistModel, CategoryModel } from "@/types/ArtistModel";
import { computed } from 'vue'

const router = useRouter()

const props = defineProps<{
  artist?: ArtistModel,
  editable?: boolean
}>()

function editPage() {
  router.push('/profil/edit')
}

const categories_displayed = computed( () => {
  if (props.artist ) {
    return props.artist.categories.join(", ")
  }
  return ""
})


const bgImgPlaceholder = computed( () => {
  if (props.artist) {
    return {
      backgroundImage: `url(${ props.artist.photo_url  || require('@/assets/img/photo-placeholder.png')})`
    }
  }
  return { backgroundImage: `url(${require('@/assets/img/photo-placeholder.png')})` }
})

</script>

<style lang="sass" scoped>
.photo
  @include square(160px)

</style>